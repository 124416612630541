import { env } from '@dx-ui/framework-env';

export const REACT_QUERY_CONFIG = Object.freeze({
  appId: env('DX_AUTH_API_CUSTOMER_APP_ID'),
  appName: env('APP_NAME'),
  incompleteLoggingEnabled: env('RUMCS_CAPTURE_CUSTOM_ERRORS') === 'enabled',
  gqlEndpoints: {
    browser: env('BROWSER_DX_GQL_URI'),
    server: process.env.SERVER_DX_GQL_URI as string,
  },
  authEndpoints: {
    browser: env('DX_AUTH_API_CLIENT_URI'),
    server: process.env.DX_AUTH_API_SERVER_URI as string,
  },
});
