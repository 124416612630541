import { useMemo } from 'react';
import type { ConversionCurrencyOptions } from '../format-convert';
import { convert } from '../format-convert';
import { useCurrencyConversion } from './use-currency-conversion';

const getCurrencyCode = (selectedCurrency: string | null): string => selectedCurrency || 'USD';

export const formatPrice = (
  convertedPrice: number | undefined,
  toCurrencyCode: string,
  toCurrencyData: ConversionCurrencyOptions | undefined
) => {
  return toCurrencyData?.currencySymbolFormat
    ? toCurrencyData.currencySymbolFormat.replace(/\{0\}/, convertedPrice?.toLocaleString() || '')
    : `${convertedPrice?.toLocaleString()} ${toCurrencyCode}`;
};

export const useConvertFormatPrice = ({
  fromCurrency = '',
  toCurrency = '',
  val,
}: {
  fromCurrency?: string | null;
  language?: string;
  toCurrency?: string | null;
  val?: number;
}) => {
  const { currenciesError, currenciesMap } = useCurrencyConversion();

  const convertFormatPrice = useMemo(() => {
    let convertedPrice = val;
    const fromCurrencyData = currenciesMap?.get(getCurrencyCode(fromCurrency));
    const toCurrencyData = currenciesMap?.get(getCurrencyCode(toCurrency));

    // If toCurrency is specified and there is no error, proceed with conversion and formatting
    if (toCurrency && !currenciesError && fromCurrencyData && toCurrencyData) {
      const convertedValue = convert(val, fromCurrencyData, toCurrencyData, 0, '').price;
      convertedPrice = convertedValue || val;
    }

    const formattedPrice = formatPrice(convertedPrice, getCurrencyCode(toCurrency), toCurrencyData);

    return { convertedPrice, formattedPrice, toCurrency: toCurrencyData };
  }, [val, toCurrency, currenciesError, fromCurrency, currenciesMap]);

  return convertFormatPrice;
};
