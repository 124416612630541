import { RuleEngine, type Rule } from '@dx-ui/utilities-url-config';
import type { FeatureToggles_FeatureConfigsQuery } from '../../gql/operations';

export const isTranslatedUrl = (
  serverSideRulesAndToggles: FeatureToggles_FeatureConfigsQuery | null,
  locale: string | undefined,
  resolvedUrl: string
) => {
  const tranlateUrlsRuleEngine = new RuleEngine(
    serverSideRulesAndToggles?.featureConfigs.find(
      (config) => config.name === 'rule-ui-translate-urls'
    ) as Rule
  );

  return locale === 'en'
    ? true
    : tranlateUrlsRuleEngine.run({
        url: resolvedUrl,
        language: locale,
      });
};
