import {
  HotelSummaryOptionsDocument,
  HotelSummaryOptions_VariantDocument,
} from './../../../gql/queries';
import type { QueryClient } from '@tanstack/react-query';
import type {
  Geocode_HotelSummaryOptionsQuery,
  Geocode_HotelSummaryOptions_VariantQuery,
  HotelSummaryOptionsQuery,
  HotelSummaryOptions_GeocodePageQuery,
  HotelSummaryOptions_GeocodePage_VariantQuery,
  HotelSummaryOptions_VariantQuery,
} from '../../../gql/types';
import type { HotelType } from '../../../providers/app-provider/app-provider.types';

export const getHashedHotelSummaryOptionsDataFromCache = (
  queryClient: QueryClient,
  cacheData: Record<string, HotelType>,
  isImageAspectRatiosToggleEnabled?: boolean
) =>
  queryClient
    .getQueriesData<HotelSummaryOptionsQuery | HotelSummaryOptions_VariantQuery>({
      queryKey: [
        isImageAspectRatiosToggleEnabled
          ? HotelSummaryOptionsDocument
          : HotelSummaryOptions_VariantDocument,
      ],
    })
    .reduce((hashMap, cacheEntry) => {
      const normalizedCacheEntry = cacheEntry?.[1]?.hotelSummaryOptions?.hotels.reduce(
        (normalizedData, cacheValue) => {
          if (cacheValue?.ctyhocn) normalizedData[cacheValue?.ctyhocn] = cacheValue;
          return normalizedData;
        },
        {} as Record<string, HotelType>
      );
      return { ...hashMap, ...normalizedCacheEntry };
    }, cacheData || ({} as Record<string, HotelType>));

type THsoHotel = Record<
  string,
  NonNullable<
    | HotelSummaryOptionsQuery['hotelSummaryOptions']
    | HotelSummaryOptions_VariantQuery['hotelSummaryOptions']
  >['hotels'][0]
>;
export type THotelSummaryOptionsData =
  | HotelSummaryOptionsQuery
  | HotelSummaryOptions_VariantQuery
  | Geocode_HotelSummaryOptionsQuery['geocode']
  | Geocode_HotelSummaryOptions_VariantQuery['geocode']
  | HotelSummaryOptions_GeocodePageQuery['geocodePage']
  | HotelSummaryOptions_GeocodePage_VariantQuery['geocodePage'];

export const hashHotelSummaryOptionsData = (
  hotelSummaryOptionsData: THotelSummaryOptionsData | undefined | null,
  cacheData: THsoHotel
) => {
  const isHotelSummaryOptionsQuery = (
    data: THotelSummaryOptionsData | undefined | null
  ): data is HotelSummaryOptionsQuery | HotelSummaryOptions_VariantQuery =>
    data?.hotelSummaryOptions?.hotels !== undefined;
  //guard that HSO defined needed or tsc throws a call signature error on reduce
  if (isHotelSummaryOptionsQuery(hotelSummaryOptionsData)) {
    return (
      hotelSummaryOptionsData?.hotelSummaryOptions?.hotels?.reduce((accum, hotel) => {
        accum[hotel?.ctyhocn] = hotel;
        return accum;
      }, cacheData || ({} as THsoHotel)) || {}
    );
  }
  return {};
};
