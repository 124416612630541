import { useState, useCallback, useEffect } from 'react';
import { setMinutes, setSeconds, getMinutes, getSeconds, subSeconds } from 'date-fns';

export const useTimer = (minutesValue: number, isActiveValue: boolean) => {
  const initialTime = setMinutes(setSeconds(new Date(), 0), minutesValue);

  const [minuteTime, setMinuteTime] = useState(initialTime);

  const [isTimerActive, setIsTimerActive] = useState(isActiveValue);

  const toggleTimer = () => {
    setIsTimerActive(!isTimerActive);
  };

  const resetTimer = () => {
    setMinuteTime(setMinutes(new Date(), 0));
    setIsTimerActive(false);
  };

  const isTimedOut = !!(getMinutes(minuteTime) === 0) && !!(getSeconds(minuteTime) === 0);

  const subtractFromTimer = useCallback(() => {
    setMinuteTime((currentTime) => subSeconds(currentTime, 1));
  }, []);
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isTimerActive) {
      interval = setInterval(() => {
        subtractFromTimer();
      }, 1000);
    } else if (!isTimerActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, subtractFromTimer]);

  useEffect(() => {
    if (getMinutes(minuteTime) === 0 && getSeconds(minuteTime) === 0) {
      setIsTimerActive(false);
    }
  }, [minuteTime]);

  return { toggleTimer, resetTimer, isTimedOut, minuteTime, setMinuteTime };
};
