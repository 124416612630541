import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

type IConductricsTestParams = {
  enabled: boolean;
  agentId: string;
  variant?: string;
};
type IConductricsTestDetails = {
  enabled: boolean;
  isLoaded: boolean;
  choice?: string;
  status: AgentStatus;
};

export function useConductricsSelectionVariant(
  mvtDetails: IConductricsTestParams
): IConductricsTestDetails {
  const status = mvtDetails.enabled ? Status.OK : Status.PENDING;
  const { isLoaded, selection } = useConductricsSelection(mvtDetails.agentId, status);
  return {
    enabled: mvtDetails.enabled && isLoaded && !!selection?.choice,
    isLoaded,
    choice: selection?.choice?.toLowerCase(),
    status,
  };
}
