import { useRouter } from 'next/router';
import { useFeatureToggleConfig } from './use-feature-toggle';
import {
  useSMBContextLocalStorage,
  useSMBProgramIdLocalStorage,
  type SMBContextType,
} from '@dx-ui/utilities-use-smb-context-local-storage';
import { useAuth } from '@dx-ui/framework-auth-provider';

export const useSmbToggle = () => {
  const { guestInfo } = useAuth();
  const router = useRouter();
  const { enabled: isSMBContextSwitchingEnabled } = useFeatureToggleConfig('WEBPLAT-551');
  const { enabled: isSMBMultiBusinessEnabled } = useFeatureToggleConfig('smbMultiBusiness');
  const { smbContext } = useSMBContextLocalStorage(isSMBContextSwitchingEnabled);
  const { smbProgramId } = useSMBProgramIdLocalStorage();
  const hasMultiBusinesses = (guestInfo?.hhonors?.programAccountSummary ?? []).length > 1;
  const shouldShowMultiBusinessFlow = isSMBMultiBusinessEnabled && hasMultiBusinesses;
  const firstSMBProgramAccountId =
    guestInfo?.hhonors?.programAccountSummary?.[0]?.accountId || null;
  const programAccountId = shouldShowMultiBusinessFlow ? smbProgramId : firstSMBProgramAccountId;

  const handleSMBSwitch = async () => {
    return new Promise<(context: SMBContextType) => void>((resolve) => {
      const callback = (context: SMBContextType) => {
        const currentQuery = { ...router.query };
        if (context === 'business') {
          currentQuery.smbRate = 'true';
        } else {
          delete currentQuery.smbRate;
          delete currentQuery.programAccountId;
        }
        void router.push(
          {
            query: currentQuery,
          },
          undefined,
          { shallow: true }
        );
      };

      resolve(callback);
    });
  };
  return {
    handleSMBSwitch,
    isSMBContextSwitchingEnabled,
    smbContext,
    isSMBMultiBusinessEnabled,
    programAccountId,
  };
};
