import { useFeatureToggleConfig } from './use-feature-toggle';
import { useWrappedRouter } from './use-wrapped-router';

export const useInLanguageAutocomplete = () => {
  const {
    safeQueryParams: queryParameters,
    router: { locale },
  } = useWrappedRouter();

  const contentLocale = queryParameters?.content;
  const language = contentLocale ?? locale;

  const { config: autocompleteConfig } = useFeatureToggleConfig('config-ui-translate-autocomplete');
  const isInLanguageAutocomplete: boolean =
    language &&
    !!autocompleteConfig?.languages?.length &&
    autocompleteConfig.languages.includes(language);

  return {
    isInLanguageAutocomplete,
    language,
  };
};
