import type * as React from 'react';
import type { GetCurrencyConverterCurrenciesQuery } from '@dx-ui/queries-osc-currency-converter';
import cx from 'classnames';

type CurrencySelectorProps = {
  currencies: GetCurrencyConverterCurrenciesQuery;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  children?: React.ReactElement<HTMLOptionElement> | null;
  selectedCurrency: string | null;
  selectProps?: Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    'onChange' | 'value'
  >;
};
const CurrencySelector = ({
  children,
  currencies,
  onChange,
  selectedCurrency,
  selectProps = {},
}: CurrencySelectorProps) => {
  const { className, ...restSelectProps } = selectProps;

  return (
    <select
      className={cx('form-select text-sm font-normal leading-4 md:text-base', className)}
      {...restSelectProps}
      onChange={onChange}
      value={selectedCurrency || undefined}
    >
      {children}
      {currencies.currencies.map((currency) => (
        <option
          aria-label={currency.description || undefined}
          data-testid={`currency-converter-option-${currency.currencyCode}`}
          key={`currencyOption${currency.currencyCode}`}
          value={currency.currencyCode || undefined}
        >{`${currency.currencyCode} - ${currency.description}`}</option>
      ))}
    </select>
  );
};

export default CurrencySelector;
