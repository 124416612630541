/* eslint-disable no-case-declarations */

/**
 * Design based loosely on this article by Kent C. Dodds:
 * - https://kentcdodds.com/blog/how-to-use-react-context-effectively
 * */

import * as React from 'react';
import { checkIfHotelIsWithinBounds, getIsPageBrandFilterEnabled } from './app-provider.utils';
import type { HotelType } from './app-provider.types';
import type { ActiveFiltersState } from '../../components/filters/filter.constants';
import type {
  ShopMultiPropAvailQuery,
  Geocode_HotelSummaryOptionsQuery,
} from '../../gql/operations';
import { returnInitialBounds } from '../../components/map/map.utils';
import { hashHotelSummaryOptionsData } from '../../hooks/use-hotel-summary-options/utils/get-hashed-hotel-summary-options-data-from-cache';
import type { PageType, SortByDropDownValues } from '../../utils';
import { defaultPageType } from '../../utils';
import type {
  Geocode_HotelSummaryOptions_VariantQuery,
  HotelSummaryOptionsQuery,
  HotelSummaryOptions_GeocodePageQuery,
} from '../../gql/types';
import { useFilterDispatch } from '../filter-provider';

type AppState = {
  activeProperty: null | string;
  boundingBox: {
    south: number;
    west: number;
    north: number;
    east: number;
  };
  selectedCurrency: null | string;
  hasConnectingRooms: boolean;
  hotelsInBounds: Record<string, HotelType>;
  isListVisible: boolean;
  paginationIndex: number;
  pageType: PageType;
  selectedCtyhocn: null | string;
  showHotelImages: boolean;
  sortType: SortByDropDownValues;
  shouldUsePoints: boolean;
  hasSpecialRate?: boolean;
  isOneLink?: boolean;
  hotelsToCompare: string[]; //NHCSEARCH-5249 MVT Compare Properties
  compareHotelsToggle?: boolean; //NHCSEARCH-5249 MVT Compare Properties
};

type AppActions =
  | {
      type: 'APPLY_FILTERS_TO_HOTELS_IN_BOUNDS';
      payload: {
        activeFiltersState: Partial<ActiveFiltersState>;
        pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]> | undefined;
        useLeadPrice?: boolean;
        maxPoints?: number;
        hasMPACallResolved?: boolean;
        brandCode?: string;
      };
    }
  | {
      type: 'INITIALIZE_APP';
      payload: {
        activeFiltersState: ActiveFiltersState;
        boundingBox: {
          south: number;
          west: number;
          north: number;
          east: number;
        };
        initialHashedHotelSummaryOptionsData: Record<string, HotelType>;
        usePoints: boolean;
        hasConnectingRooms: boolean;
        hasMPACallResolved?: boolean;
        sortType: SortByDropDownValues;
        selectedCurrency: string;
        pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]> | undefined;
        useLeadPrice?: boolean;
        maxPoints?: number;
        pageType?: PageType;
        brandCode?: string;
      };
    }
  | { type: 'RESET_AND_APPLY_FILTERS_TO_HOTEL_IN_BOUNDS' }
  | { type: 'SET_PDP_CTYHOCN'; payload: string | null }
  | { type: 'SET_HOTELS_TO_COMPARE'; payload: string[] } //NHCSEARCH-5249 MVT Compare Properties
  | { type: 'SET_COMPARE_HOTELS_TOGGLE'; payload: boolean } //NHCSEARCH-5249 MVT Compare Properties
  | { type: 'SET_HAS_CONNECTING_ROOMS'; payload: boolean }
  | {
      type: 'SET_DISPLAY_CURRENCY';
      payload: { currency: string; priceFilter?: Tuple<2, number> | null };
    }
  | { type: 'SET_SORT_TYPE'; payload: SortByDropDownValues }
  | { type: 'SET_HIGHLIGHTED_MAP_CTYHOCN'; payload: string | null }
  | { type: 'SET_IS_LIST_VISIBLE'; payload: boolean }
  | { type: 'SET_PAGE_TYPES'; payload: PageType }
  | { type: 'SET_PAGINATION_INDEX'; payload: number }
  | { type: 'SET_SHOW_HOTEL_IMAGES'; payload: boolean }
  | { type: 'SET_USE_POINTS'; payload: boolean }
  | { type: 'SET_HAS_SPECIAL_RATE'; payload: boolean }
  | { type: 'UNSET_PDP_CTYHOCN' }
  | { type: 'UNSET_HIGHLIGHTED_MAP_CTYHOCN' }
  | {
      type: 'UPDATE_HOTELS_IN_BOUNDS';
      payload: {
        boundingBox: {
          south: number;
          west: number;
          north: number;
          east: number;
        };
        hashedHotelSummaryOptionsHotelData: Record<string, HotelType>;
        pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>;
        useLeadPrice?: boolean;
        maxPoints?: number;
        hasMPACallResolved?: boolean;
      };
    };

export type Dispatch = (action: AppActions) => void;

export const AppContext = React.createContext<
  | {
      state: AppState;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

export function AppReducer(state: AppState, action: AppActions): AppState {
  switch (action.type) {
    case 'APPLY_FILTERS_TO_HOTELS_IN_BOUNDS':
      const { brandFilters, amenityFilters } = action.payload.activeFiltersState;

      return {
        ...state,
        hasConnectingRooms: (amenityFilters || []).includes('adjoiningRooms'),
        paginationIndex: 0,
        pageType: {
          ...state.pageType,
          isPageBrandFilterEnabled: getIsPageBrandFilterEnabled(
            brandFilters || [],
            action.payload.brandCode
          ),
        },
      };
    case 'INITIALIZE_APP':
      const initialHotelsInBounds = Object.values(
        action.payload.initialHashedHotelSummaryOptionsData
      ).reduce((hotel, data) => {
        if (checkIfHotelIsWithinBounds(data, action.payload.boundingBox)) {
          hotel[data.ctyhocn] = data;
        }
        return hotel;
      }, {} as Record<string, HotelType>);

      const pageType = {
        ...state.pageType,
        isPageBrandFilterEnabled: getIsPageBrandFilterEnabled(
          action.payload.activeFiltersState.brandFilters,
          action.payload.brandCode
        ),
      };
      return {
        ...state,
        hasConnectingRooms:
          action.payload.hasConnectingRooms ||
          action.payload.activeFiltersState?.amenityFilters?.includes('adjoiningRooms') ||
          false,
        hotelsInBounds: initialHotelsInBounds,
        shouldUsePoints: action.payload.usePoints || false,
        sortType: action.payload.sortType,
        selectedCurrency: action.payload.selectedCurrency,
        pageType: action.payload.pageType
          ? {
              ...pageType,
              ...action.payload.pageType,
            }
          : pageType,
        hotelsToCompare: [],
      };
    case 'RESET_AND_APPLY_FILTERS_TO_HOTEL_IN_BOUNDS':
      return {
        ...state,
        hasConnectingRooms: false,
        paginationIndex: 0,
        pageType: { ...state.pageType, isPageBrandFilterEnabled: false },
      };
    case 'SET_HOTELS_TO_COMPARE': //NHCSEARCH-5249 MVT Compare Properties
      return { ...state, hotelsToCompare: [...action.payload] };
    case 'SET_COMPARE_HOTELS_TOGGLE': //NHCSEARCH-5249 MVT Compare Properties
      return { ...state, compareHotelsToggle: action.payload };
    case 'SET_PDP_CTYHOCN':
      return { ...state, selectedCtyhocn: action.payload };
    case 'SET_HAS_CONNECTING_ROOMS':
      return { ...state, hasConnectingRooms: action.payload };
    case 'SET_IS_LIST_VISIBLE':
      return { ...state, isListVisible: action.payload };
    case 'SET_PAGE_TYPES':
      return { ...state, pageType: { ...state.pageType, ...action.payload } };
    case 'SET_PAGINATION_INDEX':
      return { ...state, paginationIndex: action.payload };
    case 'SET_HIGHLIGHTED_MAP_CTYHOCN':
      return { ...state, activeProperty: action.payload };
    case 'SET_SHOW_HOTEL_IMAGES':
      return { ...state, showHotelImages: action.payload };
    case 'SET_USE_POINTS':
      return {
        ...state,
        shouldUsePoints: action.payload,
      };
    case 'SET_HAS_SPECIAL_RATE':
      return {
        ...state,
        hasSpecialRate: action.payload,
      };
    case 'SET_DISPLAY_CURRENCY':
      return {
        ...state,
        selectedCurrency: action.payload.currency,
      };
    case 'SET_SORT_TYPE':
      return { ...state, sortType: action.payload };
    case 'UNSET_PDP_CTYHOCN':
      return { ...state, selectedCtyhocn: null };
    case 'UNSET_HIGHLIGHTED_MAP_CTYHOCN':
      return { ...state, activeProperty: null };
    case 'UPDATE_HOTELS_IN_BOUNDS':
      const newHookHotelsInBounds = Object.values(
        action.payload.hashedHotelSummaryOptionsHotelData
      ).reduce((hotel, data) => {
        if (checkIfHotelIsWithinBounds(data, action.payload.boundingBox)) {
          hotel[data.ctyhocn] = data;
        }
        return hotel;
      }, {} as Record<string, HotelType>);

      return {
        ...state,
        boundingBox: action.payload.boundingBox,
        hotelsInBounds: newHookHotelsInBounds,

        paginationIndex: 0,
      };
    default:
      return state;
  }
}

// Paul - Just not going to worry about this type. This hashed data is going away next PR
type AppProviderProps = {
  children: React.ReactNode;
  initialHotelSummaryOptionsData?:
    | (Geocode_HotelSummaryOptionsQuery &
        HotelSummaryOptionsQuery &
        HotelSummaryOptions_GeocodePageQuery)
    | Geocode_HotelSummaryOptionsQuery['geocode']
    | Geocode_HotelSummaryOptions_VariantQuery['geocode']
    | undefined
    | null;

  isOneLink?: boolean;
};

const AppProvider = ({
  children,
  initialHotelSummaryOptionsData,
  isOneLink = false,
}: AppProviderProps) => {
  const initialHashedHotelData = hashHotelSummaryOptionsData(initialHotelSummaryOptionsData, {});
  // If bounds are available on hotelSummaryOptions (the expanded bounding box), then use it. Othwise use standard bounds.
  let hsoBounds = undefined;
  let geometryBounds = undefined;

  if (initialHotelSummaryOptionsData && 'geocode' in initialHotelSummaryOptionsData) {
    hsoBounds = initialHotelSummaryOptionsData?.geocode?.hotelSummaryOptions?.bounds;
    geometryBounds = initialHotelSummaryOptionsData?.geocode?.match?.geometry?.bounds;
  }

  const initialBounds = returnInitialBounds(hsoBounds, geometryBounds);

  const defaultState: AppState = {
    activeProperty: null,
    boundingBox: initialBounds,
    selectedCurrency: null,
    hasConnectingRooms: false,
    hotelsInBounds: initialHashedHotelData,
    paginationIndex: 0,
    selectedCtyhocn: null,
    showHotelImages: false,
    sortType: 'DISTANCE',
    isListVisible: true,
    shouldUsePoints: false,
    hasSpecialRate: false,
    pageType: { ...defaultPageType },
    isOneLink,
    hotelsToCompare: [],
  };
  const [state, dispatch] = React.useReducer(AppReducer, defaultState);
  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

function useAppState() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppState() must be used within AppProvider');
  }

  return context.state;
}

function useAppDispatch() {
  const context = React.useContext(AppContext);
  const filterDispatch = useFilterDispatch();

  if (context === undefined) {
    throw new Error('useAppDispatch() must be used within AppProvider');
  }

  const { dispatch } = context;

  const combinedDispatch = React.useCallback(
    (action: AppActions) => {
      switch (action.type) {
        case 'APPLY_FILTERS_TO_HOTELS_IN_BOUNDS': {
          const { activeFiltersState } = action.payload;
          filterDispatch({
            type: 'SET_ACTIVE_FILTERS_STATE',
            payload: activeFiltersState,
          });
          break;
        }
        case 'INITIALIZE_APP': {
          const { hasConnectingRooms, activeFiltersState } = action.payload;
          const updatedActiveFiltersState =
            hasConnectingRooms && activeFiltersState.amenityFilters.indexOf('adjoiningRooms') === -1
              ? {
                  ...activeFiltersState,
                  amenityFilters: [...activeFiltersState.amenityFilters, 'adjoiningRooms'],
                }
              : activeFiltersState;
          filterDispatch({
            type: 'SET_ACTIVE_FILTERS_STATE',
            payload: updatedActiveFiltersState,
          });
          break;
        }
        case 'RESET_AND_APPLY_FILTERS_TO_HOTEL_IN_BOUNDS': {
          filterDispatch({
            type: 'RESET_FILTERS',
          });
          break;
        }
        case 'SET_USE_POINTS': {
          filterDispatch({
            type: 'SET_ACTIVE_FILTERS_STATE',
            payload: { priceFilter: undefined },
          });
          break;
        }
        case 'SET_DISPLAY_CURRENCY': {
          if (action.payload.priceFilter) {
            filterDispatch({
              type: 'SET_ACTIVE_FILTERS_STATE',
              payload: { priceFilter: action.payload.priceFilter },
            });
          }
          break;
        }
        default:
          break;
      }

      dispatch(action);
    },
    [dispatch, filterDispatch]
  );

  return combinedDispatch;
}

export { AppProvider, useAppState, useAppDispatch };
