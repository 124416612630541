/* eslint-disable @typescript-eslint/no-var-requires */
const { default: memoizeFormatConstructor } = require('intl-format-cache');
const AddressFormatter = require('./address');

class JPAddressFormatter extends AddressFormatter {
  format(a) {
    const lines = [];

    if (this.pattern === 'short') {
      if (a.city) lines.push(a.city);
      if (a.country) lines.push(a.country);
      return lines.join(', ');
    }

    if (a.postalCode) lines.push(a.postalCode);
    if (a.city || a.addressLine1) {
      const line = [];
      if (a.city) line.push(a.city);
      if (a.addressLine1) line.push(a.addressLine1);
      if (line.length && this.pattern === 'long') {
        lines.push([line.join(', ')]);
      } else {
        lines.push(line.join(' '));
      }
    }

    if (a.addressLine2) lines.push(a.addressLine2);
    if (a.country) lines.push(a.country);
    if (this.pattern === 'long') {
      return lines.join(', ');
    }
    // stacked html
    if (this.type === 'html') {
      return lines.join('<br/>');
    }
    // stacked text
    return lines.join('\n');
  }
}

const getJPAddressFormatter = memoizeFormatConstructor(JPAddressFormatter);

module.exports = getJPAddressFormatter;
