import { useFeatureToggleConfig } from './use-feature-toggle';

export const useRenovationChip = (ctyhocn?: string) => {
  const { enabled, config } = useFeatureToggleConfig('config-renovated-properties');

  const isRenoHotel = ctyhocn && enabled && config?.renovatedProperties.includes(ctyhocn);
  const renoChipConfig = enabled ? config?.renovatedProperties : [];

  return { isRenoHotel, renoChipConfig };
};
