import type { ConstraintErrors } from '../../constants/query-parameters';

export const MAX_OCCUPANTS = 8;

export const freeFormParams = [
  'brandCode',
  'corporateCode',
  'corporateId',
  'groupCode',
  'travelAgentId',
  'page',
  'placeId',
  'pnd',
  'promo_code',
  'promoCode',
  'promotionCode',
  'query',
  'sessionToken',
];

export const singleDigitRegex = /^[0-9]+$/;
export const onlyDigitsRegex = /^[0-9]+$/;
export const adultOccuptantRegex = /^[1-8]$/;
export const childOccuptantRegex = /^[0-7]$/;
export const groupAndPromoCodeRegex = /^[0-9A-Za-z]{2,6}$/;
export const corporateCodeRegex = /^[0-9A-Za-z]{2,10}$/;
export const pndRegex = /^[0-9A-Za-z]{1,15}$/;
export const specPlanRegex = /^[0-9A-Za-z]{3,6}$/;
export const specialRateTokensRegex = /^[A-Z0-9]{1,7}$/;
export const offerIdRegex = /^-?[0-9]+$/;

export const TRUE_VALUES = ['1', 'true'];
export const FALSE_VALUES = ['0', 'false'];
export const INVALID_STRING_VALUE = ['true', 'false', 'undefined', 'null'];
export const BOOL_STRING_VALUE = ['true', 'false'];
export const validTokens = ['HCAAA', 'HCSMR', 'GDSGOV', 'HCHSR', 'HCARP', 'GDSSNR'];

export const defaultConstraintErrors: ConstraintErrors = {
  arrivalDate: false,
  brandCode: false,
  brandName: false,
  corporateCode: false,
  datesFlex: false,
  dayUseWithTMTP: false,
  dayUseWithPoints: false,
  departureDate: false,
  employeeRate: false,
  friendsAndFamilyRate: false,
  groupCode: false,
  numAdults: false,
  numChildren: false,
  numRooms: false,
  offerId: false,
  ownerVIPRate: false,
  ownerHGVRate: false,
  pnd: false,
  pdp: false,
  promoCode: false,
  redeemPts: false,
  rooms: false,
  specPlan: false,
  tmtpStayLength: false,
  token: false,
  specialRateTokens: false,
  travelAgent: false,
  travelAgentRate: false,
  fromId: false,
  requestedRatesOnly: false,
};

export const booleanParams = [
  'redeemPts',
  'travelAgentRate',
  'employeeRate',
  'friendsAndFamilyRate',
  'ownerVIPRate',
  'ownerHGVRate',
  'requestedRatesOnly',
  'smbRate',
];
export const datesFlexParams = ['datesFlex', 'flexibleDates'];
export const corporateParams = ['corporateId', 'corporateCode'];
export const promoCodeParams = ['promoCode', 'promotionCode', 'promo_code'];
export const sixPieceDateParams = [
  'arrivalDay',
  'arrivalMonth',
  'arrivalYear',
  'departureDay',
  'departureMonth',
  'departureYear',
];

export const rateParams = ['aaaRate', 'aarpRate', 'seniorRate', 'governmentOrMilitaryRate'];
export const occpuancyParams = ['numRooms', 'numAdults', 'numChildren'];
export const arrivalDateParams = ['arrival', 'arrivaldate', 'arrivalDate', 'fromDate'];
export const departureDateParams = ['departure', 'departuredate', 'departureDate', 'toDate'];
export const arrayTypeParams = [
  'token',
  'specialRateTokens',
  'specPlan',
  'f-amenityIds',
  'f-brandCodes',
  'f-price',
];
export const filterParams = [
  'f-amenityIds',
  'f-attributeIds',
  'f-brandCodes',
  'f-price',
  'f-tripAdvisorRatings',
  'availableHotelsOnly',
];

export const supportedOfferIdPndLocales = ['en'];

export const MAX_POINTS_LOW_LIMIT = 5000;
