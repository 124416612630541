/* eslint-disable @typescript-eslint/no-var-requires */
const { convertLocaleToLanguageTag } = require('@dx-shared/language-util');
const memoize = require('fast-memoize');

const requiresLatinNumberingSystem = ['ar'];
const fallbacks = {
  no: ['nb', 'nn', 'da'], // Norwegian macrolanguage has poor support. Use Norwegian Bokmål, Norwegian Nynork, and Danish as fallbacks
};

const addLatinNumberingSystem = (languageTag) =>
  requiresLatinNumberingSystem.includes(languageTag.split('-')[0])
    ? `${languageTag}-u-nu-latn`
    : languageTag;

const addFallbacks = (languageTag) => {
  if (Intl.NumberFormat.supportedLocalesOf(languageTag).length === 0) {
    const language = languageTag.split('-')[0];
    if (fallbacks[language]) {
      return [languageTag].concat(fallbacks[language]);
    }
  }
  return languageTag;
};

const flattenLanguageTags = (languageTags) => [].concat(...languageTags);

const internalFns = {
  addLatinNumberingSystem,
  addFallbacks,
  flattenLanguageTags,
};

const transformLocale = memoize((locale) =>
  internalFns.flattenLanguageTags(
    (Array.isArray(locale) ? [].concat(locale) : [locale])
      .filter(Boolean)
      .map(convertLocaleToLanguageTag)
      .map(addLatinNumberingSystem)
      .map(addFallbacks)
  )
);

const transformArguments =
  (fn) =>
  (locale, ...args) =>
    fn(transformLocale(locale), ...args);

module.exports = {
  transformLocale,
  transformArguments,
  __test_only__: internalFns,
};
