import type { ConversionCurrencyOptions } from './currency-conversion-utils';
import { convertAndFormat } from './format/price';

type FormatAndConvertPriceArgs = {
  basePrice?: number | null;
  currencyQueryError?: boolean;
  fromCurrency?: ConversionCurrencyOptions;
  language?: string;
  toCurrency?: ConversionCurrencyOptions;
  strategy?: string;
};

const formatAndConvertPrice = ({
  basePrice,
  currencyQueryError = false,
  fromCurrency,
  language = 'en',
  toCurrency,
  strategy = 'trunc',
}: FormatAndConvertPriceArgs): string | undefined => {
  // If we dont have conversion factors or a price dont convert the price
  if (
    currencyQueryError ||
    !fromCurrency?.conversionFactor ||
    !toCurrency?.conversionFactor ||
    !Number.isFinite(basePrice)
  ) {
    return undefined;
  }
  // otherwise convert the price
  return convertAndFormat(basePrice, language, fromCurrency, toCurrency, 'symbol', 0, strategy);
};

export { formatAndConvertPrice };
