/* eslint-disable @typescript-eslint/no-var-requires */
import { parseISO, isValid, format as _format } from 'date-fns';
import { default as memoizeFormatConstructor } from 'intl-format-cache';
import { FORMAT_PATTERNS, getFormatOptions } from './format';
import { transformArguments } from '../locale';

const TIME_REGEX = /^(\d{1,2}):(\d{1,2})(:(\d{1,2})){0,1}$/;

class DateTimeFormat {
  constructor(locales, defaultPattern, pattern) {
    this.locales = locales;
    this.pattern = pattern
      ? // FIXME: For custom patterns, replace "D" with "d" for backwards compatibility for now.
        FORMAT_PATTERNS[pattern.toUpperCase()] || pattern.replace(/D/g, 'd')
      : defaultPattern;
    this.options = getFormatOptions(locales);
  }

  format(input) {
    let date;

    if (input && typeof input === 'string' && input.length) {
      // Check if a time string in the format of "HH:mm" or "HH:mm:ss" is passed in, and parse it
      const timeMatch = input.match(TIME_REGEX);
      if (timeMatch) {
        date = new Date();
        date.setHours(timeMatch[1]);
        date.setMinutes(timeMatch[2]);
        date.setSeconds(timeMatch[4] || 0);
      } else {
        // Parse ISO 8601 date input
        date = parseISO(input);
      }
    }

    // If the input is a valid JS Date, use it for formatting
    else if (input && input instanceof Date) {
      date = input;
    }

    // Format date if it was parsed successfully
    if (date && isValid(date)) {
      return _format(date, this.pattern, this.options);
    }

    return '';
  }
}

const getDateTimeFormat = transformArguments(memoizeFormatConstructor(DateTimeFormat));

const getDateFormat = (locales, pattern) =>
  getDateTimeFormat(locales, FORMAT_PATTERNS.SHORT, pattern);

const getTimeFormat = (locales, pattern) =>
  getDateTimeFormat(locales, FORMAT_PATTERNS.TIME_SHORT, pattern);

export { getDateFormat, getTimeFormat };
