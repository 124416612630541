import type { IDigitalData } from './types';
import type { SortByMetricsKeyValues } from '../utils';

export const PAGES = {
  DEFAULT_PAGE: 'default',
  SEARCH_PAGE: 'search',
  ADOBE_SEARCH_PAGE: 'Search',
  ADOBE_SEARCH_PAGE_HOME: 'Search Home',
  DEFAULT_LOCATIONS_PAGE: 'Locations',
  ADOBE_LOCATIONS_PAGE_HOME: 'Locations Home',
  ADOBE_LOCATIONS_NEAR_ME: 'Locations Near Me',
  ADOBE_POINTS_EXPLORER: 'Points Explorer',
};

export const digitalData: IDigitalData = {
  event: [],
  user: [],
  page: {
    attributes: {
      siteExperience: 'R',
    },
    category: {},
    pageInfo: {},
  },
  transaction: {
    properties: [],
    priceClicked: '',
  },
};

export const SORTBY_KEYS: SortByMetricsKeyValues = {
  DISTANCE: 'Distance',
  PRICE_ASCENDING: 'Price: low-high',
  PRICE_DESCENDING: 'Price: high-low',
  POINTS_ASCENDING: 'Points: low-high',
  POINTS_DESCENDING: 'Points: high-low',
  RATINGS_ASCENDING: 'Ratings: low-high',
  RATINGS_DESCENDING: 'Ratings: high-low',
  BRAND: 'Brand',
};

export const AMENITY_CODES = {
  adjoiningRooms: 'CON',
  freeBreakfast: 'BFST',
  eveningReception: 'RCPT',
  freeParking: 'PKG',
  freeWifi: 'WIFI',
  nonSmoking: 'SMO',
  digitalKey: 'KEY',
  concierge: 'CONC',
  hotelResidence: 'RES',
  spa: 'SPA',
  golf: 'GOL',
  tennisCourt: 'TEN',
  ski: 'SKI',
  casino: 'CAS',
  beach: 'BCH',
  resort: 'RSRT',
  allInclusive: 'INC',
  luxury: 'LUX',
  boutique: 'BTQ',
  newHotel: 'NEW',
  airportShuttle: 'SHT',
  evCharging: 'EV',
  executiveLounge: 'EX',
  streamingTv: 'STRM',
  dining: 'REST',
  indoorPool: 'INPL',
  outdoorPool: 'OTPL',
  fitnessCenter: 'FIT',
  petsAllowed: 'PET',
  roomService: 'RMSV',
  businessCenter: 'BIZ',
  meetingRooms: 'MTG',
  petsNotAllowed: 'NOPT',
};
