import type { FeatureToggles_FeatureConfigsQuery } from '../gql/operations';
import { GO_HILTON_PRIMARYSLUG, HGV_MAX_PRIMARYSLUG } from '../constants/general';
import { searchUriBuilder } from '@dx-ui/framework-uri-builder';
import type { Locale, SearchUrlParams } from '@dx-ui/framework-uri-builder';
import { parseISO } from 'date-fns';
import { searchUrlParser } from './url-parser/search-url-parser';

export const getUrlParamsString = (
  locale: string | undefined,
  path: string | undefined
): string => {
  let queryParamsString = '';
  if (path && locale) {
    const startOfParamsPos = path.indexOf('?');
    queryParamsString = startOfParamsPos > -1 ? path?.slice(startOfParamsPos) : '';
  }
  return queryParamsString;
};

const paramsToObject = (entries: IterableIterator<[string, string]>) => {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export const generateOHWSearchZeroUrl = ({
  locale,
  path,
  primarySlug,
  brandSlug,
  brandName,
}: {
  locale: string | undefined;
  path: string | undefined;
  primarySlug?: string;
  brandSlug?: string;
  brandName?: string;
}): string => {
  const params = new URLSearchParams(getUrlParamsString(locale, path));

  let parsedSearchQueryParams;
  if (!params.keys().next().done) {
    ({ parsedSearchQueryParams } = searchUrlParser({
      nextRouterUrlParamsObject: paramsToObject(params.entries()),
      language: locale as string,
    }));
    parsedSearchQueryParams.dates = {
      arrivalDate: parsedSearchQueryParams?.arrivalDate
        ? parseISO(parsedSearchQueryParams.arrivalDate)
        : '',
      departureDate: parsedSearchQueryParams?.departureDate
        ? parseISO(parsedSearchQueryParams?.departureDate)
        : '',
      flexibleDates: parsedSearchQueryParams?.datesFlex,
    };
  }
  return brandSlug && brandSlug === brandName && path?.includes(`/find-hotels`)
    ? ''
    : searchUriBuilder({
        locale: locale as keyof Locale,
        path: 'find-hotels',
        brandSlug,
        primarySlug,
        urlParams: parsedSearchQueryParams as Omit<SearchUrlParams, 'sortBy'>,
      });
};

export const generateFindHotelsUrl = (
  primarySlug: string,
  locale: string | undefined,
  path?: string,
  brandSlug?: string,
  brandName?: string
): string => {
  if (primarySlug === HGV_MAX_PRIMARYSLUG) {
    return searchUriBuilder({
      locale: locale as keyof Locale,
      path: 'hgv-max-find-hotels',
    });
  } else if (primarySlug === GO_HILTON_PRIMARYSLUG) {
    return searchUriBuilder({
      locale: locale as keyof Locale,
      path: 'go-hilton-find-hotels',
    });
  }
  return generateOHWSearchZeroUrl({ locale, path, primarySlug, brandSlug, brandName });
};

export const isServerSideToggleEnabled = (
  serverSideRulesAndToggles: FeatureToggles_FeatureConfigsQuery,
  toggleName: string
) =>
  serverSideRulesAndToggles?.featureToggles.find((config) => config.name === toggleName)?.enabled;
