/* eslint-disable @typescript-eslint/no-var-requires */
const getUSAddressFormatter = require('./us');
const GBAddressFormatter = require('./gb');
const JPAddressFormatter = require('./jp');
const DEAddressFormatter = require('./de');

module.exports = {
  getUSAddressFormatter: (options) => getUSAddressFormatter(options),
  getGBAddressFormatter: (options) => new GBAddressFormatter(options),
  getJPAddressFormatter: (options) => new JPAddressFormatter(options),
  getDEAddressFormatter: (options) => new DEAddressFormatter(options),
};
