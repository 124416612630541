/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAppState } from '../providers/app-provider';
import type { TransactionPropertiesDataInput } from '../utils';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import { useMetrics } from './use-metrics';
import { getTransactionPropertyData } from '../utils';

export const useTrackSearchPricing = (input: TransactionPropertiesDataInput) => {
  const metrics = useMetrics();
  const { currenciesMap, currenciesError } = useCurrencyConversion();
  const { isListVisible, paginationIndex, shouldUsePoints } = useAppState();
  useEffect(() => {
    if (isListVisible && input.hotels && input.hotels?.length > 0) {
      const { isDateSearch, hotels, lengthOfStay, pricing, selectedCurrency } = input;
      if (input.datesFlex) return;

      const displayedHotels = hotels?.map((hotel) =>
        getTransactionPropertyData({
          currenciesError: !!currenciesError,
          currenciesMap,
          hotel,
          isDateSearch,
          lengthOfStay,
          selectedCurrency,
          priceInfo: pricing?.[hotel?.ctyhocn],
          shouldUsePoints,
        })
      );
      if (displayedHotels?.length > 0 && !displayedHotels.every((hotel) => hotel?.price === 'NA'))
        metrics.searchPricing(displayedHotels);
    }
  }, [
    isListVisible,
    JSON.stringify(input.hotels),
    paginationIndex,
    input.selectedCurrency,
    shouldUsePoints,
  ]);
  return;
};
