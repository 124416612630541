import { useMemo } from 'react';
import { useGetCurrencyConverterCurrenciesQuery } from '@dx-ui/queries-osc-currency-converter';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { Currency } from '../currency-converter';

export const useCurrencyConversion = () => {
  const {
    data: currencyData,
    isLoading: isCurrenciesLoading,
    error: currenciesError,
  } = useGetCurrencyConverterCurrenciesQuery(
    {
      language: 'en',
    },
    { enabled: isBrowser }
  );

  // currency answer never changes after initial call. Rather than constantly parsing an array of objects for lookups create a simple map for conversion
  const currenciesMap = useMemo(() => {
    const map = new Map<string, Currency>();

    if (!currenciesError && !isCurrenciesLoading) {
      currencyData?.currencies?.forEach((currency) => {
        // graph technically does not guarantee values. So if currencyCode is missing dont push
        if (currency?.currencyCode) {
          map.set(currency?.currencyCode, {
            conversionFactor: currency.conversionFactor,
            currencyCode: currency.currencyCode,
            currencySymbol: currency.currencySymbol,
            currencySymbolFormat: currency.currencySymbolFormat,
            description: currency.description,
          });
        }
      });
    }

    return map;
  }, [currenciesError, currencyData?.currencies, isCurrenciesLoading]);

  return {
    currencies: currencyData?.currencies,
    currenciesMap,
    currenciesError,
    isCurrenciesLoading,
  };
};
