import { createContext } from 'react';
import type { TaggedConfig } from '@dx-shared/rule-engine';

export type Loadable = {
  isLoading: boolean;
};

export type ConfigRuleState = {
  isLoading: boolean;
  taggedConfig?: TaggedConfig;
};

const ConfigRuleContext = createContext<ConfigRuleState>({
  isLoading: false,
});
ConfigRuleContext.displayName = 'ConfigRuleContext';

export { ConfigRuleContext };
