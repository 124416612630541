/* eslint-disable @typescript-eslint/no-var-requires */
const { default: memoizeFormatConstructor } = require('intl-format-cache');
const address = require('./formatter/factory');

class AddressFormat {
  constructor(countryCode, options) {
    this.options = options || {};
    this.countryCode = countryCode;
  }

  getFormatter() {
    return address(this.countryCode, this.options);
  }

  format(input) {
    return this.getFormatter().format(input);
  }
}

const getAddressFormat = memoizeFormatConstructor(AddressFormat);

module.exports = getAddressFormat;
