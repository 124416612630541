import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { VARIANTS } from '../constants';

const getMVTVariants = ({
  MVTVariants,
  isLoaded,
  MVTStatus,
  choice,
}: {
  MVTVariants?: readonly string[];
  isLoaded: boolean;
  MVTStatus: AgentStatus;
  choice: string | undefined;
}): Record<string, boolean> => {
  //if MVT (>2 variants) return obj with variant statuses
  if (MVTVariants?.length) {
    const variantSelections: Record<string, boolean> = {};
    MVTVariants.forEach((variant) => {
      variantSelections[variant] = isLoaded && MVTStatus === Status.OK && variant === choice;
    });
    return variantSelections;
  }
  //if simple A/B test, return b variant
  const ABVariants = {
    a: isLoaded && MVTStatus === Status.OK && choice === VARIANTS.Default,
    b: isLoaded && MVTStatus === Status.OK && choice === VARIANTS.VariantB,
  };
  return ABVariants;
};
const MVTStatus = (isProvisional: boolean | null) => {
  if (isProvisional !== null) return isProvisional ? Status.OK : Status.PENDING;
  return Status.OK;
};

type TGetMVTSelections = {
  agentId: string;
  /**bool that toggles provisional state, if not passed, MVT status will default to OK */
  isProvisional?: boolean | null;
  MVTVariants?: readonly string[];
};
export const useGetMVTSelections = ({
  agentId,
  isProvisional = null,
  MVTVariants,
}: TGetMVTSelections) => {
  // Use agentStatus to get selections for  provisional selection test

  const status = MVTStatus(isProvisional);

  const { isLoaded, selection } = useConductricsSelection(agentId, status);

  const isMVTVariant = getMVTVariants({
    MVTVariants,
    isLoaded,
    MVTStatus: status,
    choice: selection?.choice?.toLowerCase(),
  });
  return {
    isMVTVariant,
    isLoaded,
  };
};
