/* eslint-disable @typescript-eslint/no-var-requires */
const memoize = require('fast-memoize');

/**
 * Supported i18n locales.
 */
const I18N_CONFIGS = Object.freeze({
  'en-US': require('date-fns/locale/en-US'), // eslint-disable-line global-require
  'fr-FR': require('date-fns/locale/fr'), // eslint-disable-line global-require
  'zh-CN': require('date-fns/locale/zh-CN'), // eslint-disable-line global-require
});

/**
 * Use en-US as the default i18n locale.
 */
const DEFAULT_I18N_CONFIG = I18N_CONFIGS['en-US'];

/**
 * Customized date format patterns that are used in GW.
 */
const FORMAT_PATTERNS = Object.freeze({
  FULL: 'EEEE, d MMM yyyy',
  LONG: 'MMMM d, yyyy',
  MEDIUM: 'dd MMM yyyy',
  SHORT: 'MM/dd/yyyy',
  HOUR: 'h a',
  TIME: 'h:mm:ss a',
  TIME_SHORT: 'h:mm a',
});

/**
 * Determine the format options based on the locale(s) provided.
 * Defaults to the en-US format options if the language isn't supported.
 *
 * @param {*} locales
 */
const getFormatOptions = memoize((locales) => {
  const locale = Array.isArray(locales) ? locales.find((loc) => I18N_CONFIGS[loc]) : locales;
  return {
    locale: I18N_CONFIGS[locale] || DEFAULT_I18N_CONFIG,
    // Set to true to allows usage of the week-numbering year tokens "YY" and "YYYY"; see: https://git.io/fxCyr
    useAdditionalWeekYearTokens: true,
  };
});

module.exports = {
  FORMAT_PATTERNS,
  getFormatOptions,
};
