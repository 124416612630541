import * as React from 'react';
import type { ActiveFiltersState } from '../../components/filters/filter.constants';
import { defaultActiveFiltersState } from '../../components/filters/filter.constants';

type FilterActions =
  | { type: 'SET_ACTIVE_FILTERS_STATE'; payload: Partial<ActiveFiltersState> }
  | { type: 'RESET_FILTERS' };

export type FilterDispatch = (action: FilterActions) => void;

export const FilterContext = React.createContext<
  | {
      state: ActiveFiltersState;
      dispatch: FilterDispatch;
    }
  | undefined
>(undefined);

export function FilterReducer(
  state: ActiveFiltersState,
  action: FilterActions
): ActiveFiltersState {
  switch (action.type) {
    case 'SET_ACTIVE_FILTERS_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_FILTERS':
      return defaultActiveFiltersState;
    default:
      return state;
  }
}

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(FilterReducer, defaultActiveFiltersState);

  const value = { state, dispatch };
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

function useFilterState() {
  const context = React.useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterState must be used within a FilterProvider');
  }

  return context.state;
}

function useFilterDispatch() {
  const context = React.useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterDispatch must be used within a FilterProvider');
  }

  return context.dispatch;
}

export { FilterProvider, useFilterState, useFilterDispatch };
