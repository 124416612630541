import type { TaggedConfig } from '@dx-shared/rule-engine';
import { ConfigRuleEngine } from '@dx-shared/rule-engine';
import type { GetServerSidePropsContext } from 'next';
import type { IncomingMessage, ServerResponse } from 'http';
import type { ConfigRuleProps } from './utils/config';
import {
  getFactsByHeaders,
  getFactsByUrlQuery,
  queryFeatureConfigs,
  setCustomResponseHeaders,
} from './utils/config';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export async function getServerFeatureConfigs(
  req: IncomingMessage,
  res: ServerResponse,
  query: GetServerSidePropsContext['query'],
  options: ConfigRuleProps
): Promise<TaggedConfig> {
  const { customFactResolver } = options;
  const headerFacts = getFactsByHeaders(req.headers);
  const urlFacts = getFactsByUrlQuery(query);
  const customFacts = customFactResolver ? customFactResolver() : {};
  const configRule = await queryFeatureConfigs(options);
  const taggedConfig = new ConfigRuleEngine(configRule).run({
    ...headerFacts,
    ...urlFacts,
    ...customFacts,
  });
  setCustomResponseHeaders(res, [
    { name: 'dx-page-url-suffix', value: taggedConfig.tags.join('/') },
  ]);
  return taggedConfig;
}

export function setTrackingInfo(taggedConfig: TaggedConfig) {
  const { config, tags } = taggedConfig;
  if (isBrowser) {
    const attributes = window.digitalData?.page?.attributes;
    if (attributes) {
      attributes.configurationFeatures = config;
      attributes.configurationRules = tags;
    }
  }
}

export function getClientFeatureConfigs(props: ConfigRuleProps) {
  return queryFeatureConfigs(props);
}
