/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import type { ConfigRuleState } from './config-rule-context';
import { ConfigRuleContext } from './config-rule-context';
import type { ConfigRule, TaggedConfig, Config } from '@dx-shared/rule-engine';
import { ConfigRuleEngine } from '@dx-shared/rule-engine';
import type { ConfigRuleProps } from './utils/config';
import { getClientFeatureConfigs, setTrackingInfo } from './config-rule-util';
import { logError } from '@dx-ui/framework-logger';

// pass this one through
export type { TaggedConfig, Config };

export type ConfigRuleProviderProps = ConfigRuleProps & {
  children: React.ReactNode;
  initialConfigs: TaggedConfig;
};

export function ConfigRuleProvider(props: ConfigRuleProviderProps) {
  const { children, endpoint, appName, rules = [], initialConfigs, customFactResolver } = props;
  const [state, dispatch] = React.useState<ConfigRuleState>({
    ...{ taggedConfig: initialConfigs, isLoading: true },
  });
  const [clientSideConfigQueryResult, setClientSideConfigQueryResult] = React.useState<
    ConfigRule | ConfigRule[] | null
  >(null);

  // break out use effect into two steps
  // 1. will run the query and save the results in clientSideConfigQueryResult
  // this should only run on mount since endpoint appName, and rules are not expected to change per render
  React.useEffect(() => {
    if (rules.length > 0) {
      getClientFeatureConfigs({ endpoint, appName, rules })
        .then((rule) => setClientSideConfigQueryResult(rule))
        .catch((error) =>
          logError('CONFIG_RULE_PROVIDER', error, 'Unable to set client side config query result.')
        );
    }
  }, [endpoint, appName, rules]);

  // 2. When there is a clientSide config, run it through the rules engine,
  // OR if there is a change to the customFactResolver then run the rules engine again
  // this way when if customFactResolver changes, query does not need to be re-run
  React.useEffect(() => {
    if (clientSideConfigQueryResult) {
      const customFacts = customFactResolver ? customFactResolver() : {};
      const clientTaggedConfig = new ConfigRuleEngine(clientSideConfigQueryResult).run(customFacts);
      const taggedConfig: TaggedConfig = {
        tags: [...initialConfigs.tags, ...(clientTaggedConfig?.tags || [])],
        config: { ...initialConfigs.config, ...clientTaggedConfig?.config },
      };
      dispatch({ taggedConfig, isLoading: false });
      setTrackingInfo(taggedConfig);
    }
  }, [clientSideConfigQueryResult, customFactResolver]);

  return <ConfigRuleContext.Provider value={state}>{children}</ConfigRuleContext.Provider>;
}

export function useConfigRule() {
  const context = React.useContext(ConfigRuleContext);
  if (context === undefined) {
    throw new Error(`useConfigRule must be used within a ConfigRuleProvider`);
  }
  return context;
}
