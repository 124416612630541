import type { QueryParameters } from '../constants';
import { GO_HILTON_PRIMARYSLUG, HGV_MAX_PRIMARYSLUG } from '../constants';

export type PageType = {
  isBrand: boolean;
  isCategoryAmenity: boolean;
  isDateSearch: boolean;
  isDefaultLocations: boolean;
  isDreams: boolean;
  isGlobal: boolean;
  isGoHilton: boolean;
  isHGVMax: boolean;
  isHotelsNearMe: boolean;
  isPointsExplorer: boolean;
  isResorts: boolean;
  isSearch: boolean;
  isSearchZero: boolean;
  isPageBrandFilterEnabled?: boolean;
};
export const defaultPageType: PageType = {
  isBrand: false,
  isCategoryAmenity: false,
  isDateSearch: false,
  isDefaultLocations: false,
  isDreams: false,
  isGlobal: false,
  isGoHilton: false,
  isHGVMax: false,
  isHotelsNearMe: false,
  isPointsExplorer: false,
  isResorts: false,
  isSearch: false,
  isSearchZero: false,
  isPageBrandFilterEnabled: false,
};

export function getPageType(path: string, queryParameters?: QueryParameters) {
  const isDefaultLocations =
    path.endsWith('/locations') ||
    path.endsWith('/locations/') ||
    path.endsWith('/locations/?') ||
    path.includes('/locations/?');
  const isHotelsNearMe = path.includes('/locations/hotels-near-me/');
  const isSearch = path.includes('/search/');
  const isDreams = path.includes('/locations/') && !isDefaultLocations && !isHotelsNearMe;
  const isResorts = isDreams && path.includes('/resorts/');
  const isSearchZero = path.includes('/find-hotels/');
  let isPointsExplorer = false;
  let isHGVMax = false;
  let isGoHilton = false;
  let isDateSearch = false;
  let isBrand = false;

  if (queryParameters) {
    const primarySlug = queryParameters.primarySlug;
    isPointsExplorer = !!(
      Number.isSafeInteger(queryParameters?.maxPoints) && !!queryParameters?.redeemPts
    );
    isHGVMax = !!(primarySlug === HGV_MAX_PRIMARYSLUG);
    isGoHilton = !!(primarySlug === GO_HILTON_PRIMARYSLUG);
    isDateSearch = isSearch && !!(queryParameters?.arrivalDate || queryParameters?.departureDate);
    isBrand = !!queryParameters?.brandName;
  }

  return {
    isBrand,
    isCategoryAmenity: false,
    isDateSearch,
    isDefaultLocations,
    isDreams,
    isGoHilton,
    isHotelsNearMe,
    isHGVMax,
    isSearch,
    isSearchZero,
    isGlobal: false,
    isPointsExplorer,
    isResorts,
  };
}
