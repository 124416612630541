import type { GuestQuery } from '@dx-ui/framework-auth-provider';

export const getGuestInfo = (guestInfo: GuestQuery['guest'] | null) => {
  const isOwner = guestInfo ? guestInfo?.hhonors?.isOwner || guestInfo?.hhonors?.isOwnerHGV : false;
  const isSMBMember = !!guestInfo?.hhonors?.isSMBMember;
  const isTMTPTeamMember = guestInfo
    ? guestInfo?.hhonors?.isOwner ||
      guestInfo?.hhonors?.isTeamMember ||
      guestInfo?.hhonors?.isOwnerHGV ||
      guestInfo?.hhonors?.isLongTenure20
    : false;
  const isTPHMember = guestInfo ? guestInfo?.hhonors?.isTeamMemberSpouse : false; // Go-hilton Spouse

  const isFriendsAndFamily = guestInfo
    ? guestInfo?.hhonors?.isFamilyAndFriends ||
      guestInfo?.hhonors?.isTeamMember ||
      guestInfo?.hhonors?.isOwner ||
      guestInfo?.hhonors?.isOwnerHGV ||
      guestInfo?.hhonors?.isLongTenure10 ||
      guestInfo?.hhonors?.isLongTenure20
    : false;

  const isTMTPEligibleMember = guestInfo
    ? guestInfo?.hhonors?.isFamilyAndFriends ||
      guestInfo?.hhonors?.isLongTenure10 ||
      guestInfo?.hhonors?.isLongTenure20
    : false;

  const isHGVOwner = guestInfo ? guestInfo?.hhonors?.isOwnerHGVNew : false;

  const isTMTPUser = isFriendsAndFamily;

  return {
    isFriendsAndFamily,
    isOwner,
    isSMBMember,
    isTMTPEligibleMember,
    isTMTPTeamMember,
    isTPHMember,
    isTMTPUser,
    isHGVOwner,
  };
};
