import type * as React from 'react';
import type { GetCurrencyConverterCurrenciesQuery } from '@dx-ui/queries-osc-currency-converter';
import { useTranslation } from 'next-i18next';
import { useIsClient } from 'usehooks-ts';
import CurrencyDropDown from './currency-converter.drop-down';
import { useGetCurrencyConverterCurrenciesQuery } from '@dx-ui/queries-osc-currency-converter';

export type Currency = GetCurrencyConverterCurrenciesQuery['currencies'][number];

export type CurrencyConverterProps = {
  showDefaultOption?: boolean;
  language: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  hasStackedDisplay?: boolean;
  selectedCurrency: string;
  selectProps?: Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    'onChange' | 'value'
  >;
};

export const CurrencyConverter = ({
  showDefaultOption = false,
  language,
  onChange,
  selectedCurrency = '',
  hasStackedDisplay = false,
  ...selectProps
}: CurrencyConverterProps) => {
  const { t } = useTranslation('osc-currency-converter');
  const isClient = useIsClient();
  const { data, isLoading } = useGetCurrencyConverterCurrenciesQuery(
    {
      language,
    },
    { enabled: isClient }
  );

  // skeleton loader while waiting for graph response
  if (!isClient || isLoading) {
    return (
      <div aria-hidden data-testid="currency-converter-loading">
        {hasStackedDisplay ? (
          <div className="bg-bg-alt mb-1 mt-auto h-4 w-1/2 animate-pulse md:h-5" />
        ) : null}
        <div className="bg-bg-alt mt-auto h-9 w-36 animate-pulse md:h-11 md:w-full" />
      </div>
    );
  }

  if (data?.currencies) {
    return (
      <CurrencyDropDown
        showDefaultOption={showDefaultOption}
        currencies={data}
        onChange={onChange}
        selectedCurrency={selectedCurrency}
        hasStackedDisplay={hasStackedDisplay}
        {...selectProps}
      />
    );
  }

  return (
    <div className="z-1 flex flex-col items-end text-sm font-semibold">
      <span className="mr-2">{t('notAvailable')}</span>
    </div>
  );
};
