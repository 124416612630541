import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { SearchUrlParser, UrlParamType } from '../utils';
import { searchUrlParser } from '../utils';
import type { QueryParameters } from '../constants';
import { useRef, useEffect, useState } from 'react';
import type { ConstraintErrors } from '../constants/query-parameters';

type UseWrappedRouter = {
  constraintErrors?: ConstraintErrors;
  router: NextRouter;
  safeQueryParams?: QueryParameters;
};
/**
 * This is an extension of next/router that returns server and client side safe values.
 * This is beacuse on server side render only a few specific query params/path variables are akamai safe.
 */
export const parseBrandName = (pathName?: string) => {
  // pathname will be something like en/search/{brandName} or en/search/find-hotels. We want to basically find index at end of search and capture to end of string
  const searchSlugIndex = pathName?.indexOf('/search/');
  // if we find /search/ in uri, go to end and slice remainer of pathname
  if (searchSlugIndex && searchSlugIndex > -1) {
    return pathName?.slice(searchSlugIndex + '/search/'.length, pathName.length - 1);
  }
};

export function useWrappedRouter(): UseWrappedRouter {
  const router = useRouter();
  const queryParamsRef = useRef<SearchUrlParser | undefined>(undefined);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      const urlParams = new URLSearchParams(router.asPath.split('?')[1]);
      const params: Record<string, UrlParamType> = {};
      urlParams.forEach((value, name) => {
        params[name] = value;
      });

      queryParamsRef.current = searchUrlParser({
        nextRouterUrlParamsObject: {
          ...params,
          primarySlug: router.query.primarySlug,
          brandName: parseBrandName(window.location.pathname),
        },
        language: router.locale as string,
      });
      setIsReady(true);
    }
  }, [router.asPath, router.isReady, router.locale, router.query.primarySlug]);

  return {
    constraintErrors: queryParamsRef?.current?.constraintErrors,
    router: { ...router, isReady },
    safeQueryParams: queryParamsRef?.current?.parsedSearchQueryParams,
  };
}
