/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import { ASSET_PREFIX_SVGS, env } from '../../constants';
import { useTranslation } from 'next-i18next';
import { useMetrics } from '../../hooks';
import Image from 'next/legacy/image';
import { prefixPublicAssetUriWithAppPrefix } from '../../utils';
import type { MapLanguage } from '@dx-ui/framework-uri-builder';
import { searchUriBuilder } from '@dx-ui/framework-uri-builder';
import { useRouter } from 'next/router';

const OHW_BASE_URL = env.OHW_BASE_URL;

const Error = ({ statusCode }: { statusCode?: number }) => {
  const { t } = useTranslation('error-text');
  const { locale } = useRouter();
  const metrics = useMetrics();
  useEffect(
    () =>
      metrics.setErrorPageData({
        country: '',
        state: '',
        city: '',
        category: '',
        brandCode: '',
        errorCode: statusCode || -999,
        errorName: '', //HACK: HAVE TO DISABLE FOR NOW SINCE IN STATIC PAGE WE CANT GET ERROR NAME.
      }),
    [metrics, statusCode]
  );

  const errorText = statusCode === 404 || statusCode === undefined ? '404' : 'technical';

  const HONORS_LOGIN = '/hilton-honors/login/';

  const translateProps = {
    searchUrl: new URL(
      searchUriBuilder({
        locale: locale as MapLanguage,
        path: 'find-hotels',
      }),
      OHW_BASE_URL
    ),
    accountUrl: `${OHW_BASE_URL}${locale}${HONORS_LOGIN}`,
    brandName: 'Hilton',
    brandUrl: 'https://www3.hilton.com/en/index.html',
    brandPhone: '1-800-446-6677',
  };

  const helpfulLinks = t('helpfulLinks', { returnObjects: true, ...translateProps });
  const contactInfo = t('contactInfo', { returnObjects: true, ...translateProps });

  return (
    <div className="flex flex-col px-8 py-10 md:p-20 lg:h-screen lg:items-center lg:justify-center">
      <h1 className="text-primary mb-4 text-3xl font-black md:text-6xl">
        {t(`errorText.${errorText}.heading`)
          ? t(`errorText.${errorText}.heading`).toUpperCase()
          : ''}
      </h1>
      <h2 className="text-sm md:text-base lg:text-xl">{t(`errorText.${errorText}.subHeading`)}</h2>
      <nav className="py-8 lg:py-16">
        <ul className="flex flex-col items-start lg:flex-row lg:items-center lg:space-x-20">
          {helpfulLinks?.map &&
            helpfulLinks.map((link) => (
              <HelpfulLinkItem
                iconType={link.icon}
                key={link.linkURL}
                linkLabel={link.linkLabel}
                linkURL={link.linkURL}
              />
            ))}
        </ul>
      </nav>
      <div className="lg:divide-border flex flex-col lg:flex-row lg:divide-x rtl:lg:divide-x-reverse">
        <ContactInfo {...contactInfo.brand} />
        <ContactInfo {...contactInfo.honors} />
      </div>
    </div>
  );
};

type IHelpfulLinkItem = {
  linkURL: string;
  linkLabel: string;
  iconType: string;
};

const HelpfulLinkItem = ({ linkURL, linkLabel, iconType }: IHelpfulLinkItem) => {
  const iconMapper = (type: string) => {
    if (type === 'Search') {
      return <Image alt="" height={21} src={`${ASSET_PREFIX_SVGS}/common/search.svg`} width={21} />;
    }
    if (type === 'Account') {
      return <Image alt="" height={21} src={`${ASSET_PREFIX_SVGS}/guest/account.svg`} width={21} />;
    }
    return <Image alt="" height={21} src={`${ASSET_PREFIX_SVGS}/guest/home.svg`} width={21} />;
  };

  return (
    <li>
      <a className="text-text flex flex-nowrap items-center justify-center py-2" href={linkURL}>
        <span aria-hidden className="mr-8 block rtl:ml-8">
          {iconMapper(iconType)}
        </span>
        {linkLabel}
      </a>
    </li>
  );
};

type IContactInfo = {
  title: string;
  showIcon: boolean;
  numbers: INumber[];
  phoneText: string;
};

type INumber = {
  label: string;
  phone: string;
};

const ContactInfo = ({ title, numbers, phoneText }: IContactInfo) => (
  <div className="flex py-4 lg:px-12">
    <span aria-hidden className="mr-8 block rtl:ml-8">
      <Image
        alt=""
        height={21}
        src={prefixPublicAssetUriWithAppPrefix(`${ASSET_PREFIX_SVGS}/common/phone.svg`)}
        width={21}
      />
    </span>
    <div>
      <h3 className="mb-2 font-bold md:text-lg lg:text-xl">{title}</h3>
      {numbers?.map((number) => (
        <p className="font-medium leading-loose" key={number.label}>
          {number.label} <span className="sr-only">{phoneText}</span>
          <a href={`tel:+${number.phone}`}>{`+${number.phone}`}</a>
        </p>
      ))}
    </div>
  </div>
);

export { Error };
