type GetCMAPriceArgs = {
  cmaTotalPriceIndicator?: boolean | null;
  rateAmount?: number | null;
  rateAmountFmt?: string | null;
  totalRate?: number | null;
  totalRateFmt?: string | null;
  lengthOfStay?: number | null;
};

export const getCMAPrice = ({
  cmaTotalPriceIndicator = false,
  rateAmount,
  rateAmountFmt,
  totalRate,
  totalRateFmt,
  lengthOfStay = 0,
}: GetCMAPriceArgs) => {
  const showPricingMessage = Boolean(cmaTotalPriceIndicator && lengthOfStay && lengthOfStay >= 1);
  const showAvgPricingMessage = Boolean(showPricingMessage && lengthOfStay && lengthOfStay >= 2);

  return {
    showPricingMessage,
    showAvgPricingMessage,
    price: rateAmount,
    priceFmt: rateAmountFmt,
    totalRate: showPricingMessage ? totalRate : rateAmount,
    totalRateFmt: showPricingMessage ? totalRateFmt : rateAmountFmt,
  };
};
