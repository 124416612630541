import type { SearchDetailsDataProps } from '../types/types';

export const createPropertySearchDetailsString = (props: SearchDetailsDataProps): string => {
  const searchDetailsString = `RM:${props.numRooms}|AD:${props.numAdults}|CH:${
    props.numChildren
  }|CC:${props?.currencyCode || 'LOCAL'}${props?.datesFlex ? '|FLX' : ''}${
    props?.isUsePoints ? '|PT' : ''
  }${props?.promoCode ? '|PRO' : ''}${props?.groupCode ? '|GRP' : ''}${
    props?.corporateCode ? '|CO' : ''
  }${props?.isTravelAgents ? '|AG' : ''}${props?.isTripleA ? '|AAA' : ''}${
    props?.isAarp ? '|ARP' : ''
  }${props?.isSenior ? '|SEN' : ''}${props?.isGovernment ? '|GV' : ''}${
    !!props?.isOwner || !!props?.isHGVOwner ? '|ON' : ''
  }${props?.isEmployee ? '|TM' : ''}${props?.isFriendsAndFamily ? '|FF' : ''}${
    props?.isSMB ? '|SMB' : ''
  }${props?.isCBP ? '|CBP' : ''}`;
  return searchDetailsString;
};
