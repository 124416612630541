import distance from '@turf/distance';
import { point } from '@turf/helpers';

const DISTANCE_UNIT: Readonly<Record<string, 'miles' | 'kilometers'>> = {
  km: 'kilometers',
  mi: 'miles',
};

interface Coordinate {
  latitude: number;
  longitude: number;
}

/**
 * Calculates distance between two coordinate points.
 *
 * @param fromCoordinate
 * @param toCoordinate
 * @param distanceUnit
 * @param decimal
 */
export const calculateCenterToHotelDistance = (
  fromCoordinate: Coordinate,
  toCoordinate: Coordinate,
  distanceUnit: keyof typeof DISTANCE_UNIT,
  decimal: number
) => {
  const dist = distance(
    point([fromCoordinate.longitude, fromCoordinate.latitude]),
    point([toCoordinate.longitude, toCoordinate.latitude]),
    {
      units: DISTANCE_UNIT[distanceUnit],
    }
  );

  return Number.isInteger(decimal) && decimal >= 0 ? Number(dist.toFixed(decimal)) : dist;
};
