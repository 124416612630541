import {
  useGeocode_HotelSummaryOptionsQuery,
  useGeocode_HotelSummaryOptions_VariantQuery,
} from '../gql/queries';
import { useMemo } from 'react';
import { useWrappedRouter } from './use-wrapped-router';
import { returnInitialBounds } from '../components/map/map.utils';
import { useIsClient } from 'usehooks-ts';
import { MAX_PROPERTIES_PER_LIST_VIEW_PAGE } from '../constants';
import { useFeatureToggleConfig } from './use-feature-toggle';
import { keepPreviousData } from '@tanstack/react-query';
import { useInLanguageAutocomplete } from './use-in-language-autocomplete';

type GeocodeHSOProps = {
  address?: string;
  placeId: string | null | undefined;
  limit?: number;
};
export const useGeocodeHSO = (props: GeocodeHSOProps) => {
  const { safeQueryParams } = useWrappedRouter();
  const { address, placeId } = props;
  const isClient = useIsClient();

  //With Shallow routing, placeId is not reset and caused to display
  const { newAddress, newPlaceId } = useMemo(() => {
    let newAddress = address || '';
    let newPlaceId = placeId || null;
    if (safeQueryParams?.query && safeQueryParams?.query !== address) {
      newAddress = decodeURIComponent(
        safeQueryParams?.query || (safeQueryParams?.searchQuery as string)
      );
      newPlaceId = null;
    }

    newPlaceId =
      safeQueryParams?.placeId && safeQueryParams?.placeId !== 'null'
        ? decodeURIComponent(safeQueryParams?.placeId)
        : null;

    return { newAddress, newPlaceId };
  }, [
    address,
    placeId,
    safeQueryParams?.query,
    safeQueryParams?.placeId,
    safeQueryParams?.searchQuery,
  ]);
  const { enabled: aspectRatioImageEnabled } = useFeatureToggleConfig(
    'search-ui-aspect-ratios-images'
  );
  const { isInLanguageAutocomplete, language } = useInLanguageAutocomplete();

  const { data, isLoading, isFetching } = useGeocode_HotelSummaryOptionsQuery(
    {
      address: newAddress,
      language: isInLanguageAutocomplete && language ? language : 'en',
      placeId: newPlaceId,
      queryLimit: props?.limit || MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
    },
    {
      enabled: isClient && aspectRatioImageEnabled,
      placeholderData: keepPreviousData,
    }
  );

  const {
    data: geocodeHSOVariantData,
    isLoading: isGeocodeHSOVariantLoading,
    isFetching: isGeocodeHSOVariantFetching,
  } = useGeocode_HotelSummaryOptions_VariantQuery(
    {
      address: newAddress,
      language: isInLanguageAutocomplete && language ? language : 'en',
      placeId: newPlaceId,
      queryLimit: props?.limit || MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
    },
    {
      enabled: isClient && !aspectRatioImageEnabled,
      placeholderData: keepPreviousData,
    }
  );

  const geocodeHSOData = aspectRatioImageEnabled ? data : geocodeHSOVariantData;
  const initBounds = returnInitialBounds(
    geocodeHSOData?.geocode?.hotelSummaryOptions?.bounds,
    geocodeHSOData?.geocode?.match?.geometry?.bounds
  );

  return {
    data: geocodeHSOData,
    initBounds,
    newAddress,
    newPlaceId,
    isLoading: aspectRatioImageEnabled ? isLoading : isGeocodeHSOVariantLoading,
    isFetching: aspectRatioImageEnabled ? isFetching : isGeocodeHSOVariantFetching,
  };
};
