import type { QueryParameters } from '../../constants';
import type { ConstraintErrors } from '../../constants/query-parameters';
import { MAX_TMTP_STAY_LENGTH } from '../../constants/query-parameters';
import {
  MAX_OCCUPANTS,
  TRUE_VALUES,
  adultOccuptantRegex,
  childOccuptantRegex,
  FALSE_VALUES,
  INVALID_STRING_VALUE,
} from './url-parser.constants';
import type { SearchUrlParams } from './url-parser.types';
import { RatePlanParamTokenKeys } from './url-parser.types';

import {
  addYears,
  parseISO,
  format,
  isBefore,
  isAfter,
  isValid,
  addDays,
  isSameDay,
  differenceInDays,
  parse,
} from 'date-fns';

const dateFormatString = 'yyyy-MM-dd';

export const isValidAdults = (adults: string) =>
  !!(adultOccuptantRegex.test(adults) && parseInt(adults) <= MAX_OCCUPANTS && parseInt(adults) > 0);

export const isValidChildren = (children: string) =>
  !!(
    childOccuptantRegex.test(children) &&
    parseInt(children) <= MAX_OCCUPANTS - 1 &&
    parseInt(children) >= 0
  );

const isValidOccupancy = (children: string, adults: string) =>
  !!(
    childOccuptantRegex.test(children) &&
    adultOccuptantRegex.test(adults) &&
    parseInt(children) + parseInt(adults) <= MAX_OCCUPANTS
  );
export const addAgeFiller = (numGuest: number, agesCount: number) => {
  const guestAges = [];
  for (let i = 0; i < numGuest - agesCount; i++) {
    guestAges.push({});
  }
  return guestAges;
};

export const parseGuestAges = (
  numGuest: number,
  agesParamName: string,
  nextRouterUrlParamsObject: Partial<SearchUrlParams>
) => {
  if (numGuest === 0) return [];
  let guestAges = [{}];
  if (nextRouterUrlParamsObject[agesParamName]) {
    const agesValue = nextRouterUrlParamsObject[agesParamName] as string;
    guestAges = agesValue.split(',').map((age) => {
      const childAge = parseInt(age);
      return Number.isNaN(childAge) ? {} : { age: childAge };
    });
  }
  const ageFillers = addAgeFiller(numGuest, guestAges.length);
  guestAges.push(...ageFillers);

  return guestAges;
};

export const parseMultiRoom = (
  parsedSearchQueryParams: QueryParameters,
  nextRouterUrlParamsObject: Partial<SearchUrlParams>,
  constraintErrors: ConstraintErrors
) => {
  if (parsedSearchQueryParams.numRooms === 1) {
    let numAdults =
      parseInt(
        (nextRouterUrlParamsObject.room1NumAdults ||
          parsedSearchQueryParams.room1NumAdults) as string
      ) || parsedSearchQueryParams.numAdults;
    let numChildren =
      parseInt(
        (nextRouterUrlParamsObject.room1NumChildren ||
          parsedSearchQueryParams.room1NumChildren) as string
      ) || parsedSearchQueryParams.numChildren;
    //prevent exceeding max occupancy via deep link
    if (!isValidOccupancy(numChildren.toString(), numAdults.toString())) {
      numAdults = 1;
      numChildren = 0;
      constraintErrors.rooms = true;
    }
    return (parsedSearchQueryParams.rooms = [
      {
        adults: numAdults,
        children: numChildren,
        adultAges: parseGuestAges(numAdults, `room1AdultAges`, nextRouterUrlParamsObject),
        childAges: parseGuestAges(numChildren, `room1ChildAges`, nextRouterUrlParamsObject),
      },
    ]);
  }

  const rooms = [];
  for (let i = 0; i < parsedSearchQueryParams.numRooms; i++) {
    let adults = 1;
    let children = 0;
    // handle roomxNumChildren and roomxNumAdults
    if (
      nextRouterUrlParamsObject[`room${i + 1}NumAdults`] ||
      nextRouterUrlParamsObject[`room${i + 1}NumChildren`]
    ) {
      adults = parseInt(nextRouterUrlParamsObject[`room${i + 1}NumAdults`] as string) || 1;
      children = parseInt(nextRouterUrlParamsObject[`room${i + 1}NumChildren`] as string) || 0;
    }
    // handle numberOfAdultsX numberOfChildrenX
    else if (
      nextRouterUrlParamsObject[`numberOfAdults[${i}]`] ||
      nextRouterUrlParamsObject[`numberOfChildren[${i}]`]
    ) {
      adults = parseInt(nextRouterUrlParamsObject[`numberOfAdults[${i}]`] as string) || 1;
      children = parseInt(nextRouterUrlParamsObject[`numberOfChildren[${i}]`] as string) || 0;
    } else if (i === 0) {
      adults = parseInt(nextRouterUrlParamsObject.numAdults as string) || 1;
      children = parseInt(nextRouterUrlParamsObject.numChildren as string) || 0;
    }
    if (!isValidChildren(children.toString())) {
      children = 0;
      constraintErrors.rooms = true;
    }
    if (!isValidAdults(adults.toString())) {
      adults = 1;
      constraintErrors.rooms = true;
    }
    //handle combined n of occupants do not exceed maxOccupancy
    if (!isValidOccupancy(children.toString(), adults.toString())) {
      adults = 1;
      children = 0;
      constraintErrors.rooms = true;
    }
    // handle roomXAdultAges and roomXChildAges
    const adultAges = parseGuestAges(adults, `room${i + 1}AdultAges`, nextRouterUrlParamsObject);
    const childAges = parseGuestAges(children, `room${i + 1}ChildAges`, nextRouterUrlParamsObject);

    rooms.push({
      adults,
      children,
      adultAges,
      childAges,
    });
  }
  // If we have more than 1 room override numAdults and numChildren with first room values
  if (parsedSearchQueryParams.numRooms > 1 && rooms[0]) {
    parsedSearchQueryParams.numAdults = rooms[0].adults;
    parsedSearchQueryParams.numChildren = rooms[0].children;
  }

  return (parsedSearchQueryParams.rooms = rooms);
};

export const isDateGreater1Year = (dateInStringFormat: string) => {
  const todayDatePlus1Year = addYears(new Date(), 1);
  const date = parseISO(dateInStringFormat);
  return isAfter(date, todayDatePlus1Year);
};
export const convertToBoolean = (value: string) =>
  TRUE_VALUES.includes(value)
    ? { booleanValue: true, error: false }
    : FALSE_VALUES.includes(value)
    ? { booleanValue: false, error: false }
    : { booleanValue: false, error: true };

export const isValidFreeFormValue = (value: string) =>
  value.trim().length > 0 && !INVALID_STRING_VALUE.includes(value.toLowerCase());

export const defaultArrivalDepartureDates = (
  parsedSearchQueryParams: QueryParameters,
  constraintErrors: ConstraintErrors
) => {
  parsedSearchQueryParams.arrivalDate = format(new Date(), dateFormatString);
  parsedSearchQueryParams.departureDate = format(addDays(new Date(), 1), dateFormatString);
  constraintErrors.arrivalDate = true;
  constraintErrors.departureDate = true;
};

export const isInvalidDate = (dateInString: string): boolean => {
  const format1Date = parse(dateInString, 'yyyy-MM-dd', new Date());
  const format2Date = parse(dateInString, 'yyyyMMdd', new Date());
  return isValid(format1Date) ? false : !isValid(format2Date);
};

export const validateAndFormatArrivalDepartureDates = (
  parsedSearchQueryParams: QueryParameters,
  constraintErrors: ConstraintErrors
) => {
  const { arrivalDate, departureDate } = parsedSearchQueryParams;
  const todaysDate = format(new Date(), dateFormatString);

  if (isInvalidDate(arrivalDate) || isInvalidDate(departureDate)) {
    defaultArrivalDepartureDates(parsedSearchQueryParams, constraintErrors);
  } else if (
    isBefore(parseISO(departureDate), parseISO(arrivalDate)) ||
    isBefore(parseISO(arrivalDate), parseISO(todaysDate))
  ) {
    defaultArrivalDepartureDates(parsedSearchQueryParams, constraintErrors);
  } else {
    parsedSearchQueryParams.arrivalDate = format(parseISO(arrivalDate), dateFormatString);
    parsedSearchQueryParams.departureDate = format(parseISO(departureDate), dateFormatString);
  }
};

export const checkTMTPConstraints = (
  parsedSearchQueryParams: QueryParameters,
  constraintErrors: ConstraintErrors
) => {
  const { arrivalDate, departureDate, primarySlug } = parsedSearchQueryParams;
  if (
    primarySlug === 'go-hilton' &&
    (parsedSearchQueryParams.employeeRate ||
      parsedSearchQueryParams.friendsAndFamilyRate ||
      parsedSearchQueryParams.ownerVIPRate)
  ) {
    constraintErrors.dayUseWithTMTP = isSameDay(parseISO(arrivalDate), parseISO(departureDate));
    const lengthOfStay = differenceInDays(parseISO(departureDate), parseISO(arrivalDate));
    if (lengthOfStay > MAX_TMTP_STAY_LENGTH) constraintErrors.tmtpStayLength = true;
  }
};

export const buildTokenFromRateParams = (
  param: string,
  paramValue: string,
  parsedSearchQueryParams: QueryParameters
): void => {
  const tokenName = Object(RatePlanParamTokenKeys)[param];

  if (!TRUE_VALUES.includes(paramValue)) {
    return;
  }

  parsedSearchQueryParams.token.push(tokenName);
};

export const checkDayUseWithPoints = (
  parsedSearchQueryParams: QueryParameters,
  constraintErrors: ConstraintErrors
) => {
  if (
    parsedSearchQueryParams.arrivalDate === parsedSearchQueryParams.departureDate &&
    parsedSearchQueryParams.redeemPts
  ) {
    constraintErrors.dayUseWithPoints = true;
    parsedSearchQueryParams.redeemPts = false;
  }
};

export const getRouteParams = (query: SearchUrlParams) =>
  Object.keys(query)
    .reduce((arr, key) => {
      if (['brandName', 'language', 'primarySlug'].indexOf(key) > -1)
        arr.push(`${key}=${query[key]}`);
      return arr;
    }, [] as string[])
    .join('&');
