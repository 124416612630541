/* eslint-disable @typescript-eslint/no-var-requires */
const { default: memoizeFormatConstructor } = require('intl-format-cache');
const { transformArguments } = require('./locale');

class NumberFormat {
  constructor(locales, options) {
    const tOptions = { ...(options || {}) }; // Object.assign({}, options);
    // Don't use javascript currency formatting since it doesn't match existing globalweb currency formatting
    if (tOptions.style === 'currency') {
      tOptions.style = 'decimal';
      delete tOptions.currency;
      delete tOptions.currencyDisplay;
    }

    this.intlNumberFormat = new Intl.NumberFormat(locales, tOptions);
    this.options = options;
  }

  format(input) {
    // Don't format non-zero falsy values. Intl.NumberFormat will return 0 which isn't what we want.
    if (!Number.isFinite(input)) {
      return '';
    }

    // If the input value is -0, convert it to a +0.
    if (Object.is(input, -0)) {
      // eslint-disable-next-line no-param-reassign
      input = Math.abs(input);
    }

    return this.intlNumberFormat.format(input);
  }
}

const getNumberFormat = transformArguments(memoizeFormatConstructor(NumberFormat));

module.exports = getNumberFormat;
