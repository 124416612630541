import { URL_SLUGS, AKAMAI_HEADERS } from './constants';
import camelCase from 'lodash/camelCase';
import type { IncomingHttpHeaders, ServerResponse } from 'http';
import type { GetServerSidePropsContext } from 'next';
import type { Facts } from '@dx-shared/rule-engine';

export type ConfigRuleProps = {
  endpoint: string;
  appName: string;
  rules?: string[];
  customFactResolver?: () => Record<string, string>;
  customHeaders?: HeadersInit;
};

export function setCustomResponseHeaders(
  response: ServerResponse,
  headers: { name: string; value: number | string | string[] }[]
) {
  headers.forEach((header) => {
    const { name, value } = header;
    response.setHeader(name, value);
  });
}

export function getFactsByHeaders(
  headers: IncomingHttpHeaders,
  akamaiHeaders: string[] = AKAMAI_HEADERS
) {
  const facts: Facts = {};
  akamaiHeaders.forEach((header: string) => {
    if (headers[header]) {
      const fact = camelCase(header.replace('dx-', ''));
      facts[fact] = (headers as never)[header];
    }
  });
  return facts;
}

export function getFactsByUrlQuery(
  urlQuery: GetServerSidePropsContext['query'],
  slugs: string[] = URL_SLUGS
) {
  if (!urlQuery) {
    return null;
  }
  const facts: Facts = {};
  slugs.forEach((key: string) => {
    if (urlQuery[key]) {
      facts[key] = (urlQuery as never)[key];
    }
  });
  return facts;
}

export async function queryFeatureConfigs(props: ConfigRuleProps) {
  const { appName, endpoint, rules, customHeaders = {} } = props;
  const modifiedEndpoint = `${endpoint}?appName=${appName}&operationName=featureConfigs`;
  const query = `query featureConfigs { 
    featureConfigs(names: ${JSON.stringify(rules)}) {
      name
      conditions
      config
    } 
  }`;
  const options: RequestInit = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      ...customHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'featureConfigs',
      query,
    }),
  };
  const response = await fetch(modifiedEndpoint, options);
  const results = await response.json();
  return results?.data?.featureConfigs;
}
