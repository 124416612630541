/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ConversionCurrencyOptions } from '../currency-conversion-utils';

/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports */
const getNumberFormat = require('./number');

/**
 * Returns the decimal format of a currency.
 * Defaults to 2 if the decimal format isn't valid.
 *
 * @param {*} format
 */
const getDecimal = (currency: any) => {
  const decimal = parseInt(currency && currency.currencySymbolFormat, 10);
  return decimal >= 0 ? decimal : 2;
};

/**
 * Converts the price from one currency to another.
 *
 * Returns an object with the following fields:
 * * currency: The currency the price was converted to. Returns the from currency if unable to convert the price.
 * * price: The converted price value. Returns the input price value if unable to convert the price.
 * * decimal: The currency decimal format value.
 *
 * @param {*} price
 * @param {*} fromCurrency
 * @param {*} toCurrency
 * @param {*} decimal
 * @param {*} strategy
 */
/* eslint-disable no-param-reassign */
export const convert = (
  price: number | null | undefined,
  fromCurrency: ConversionCurrencyOptions,
  toCurrency: ConversionCurrencyOptions,
  decimal: number,
  strategy: string
) => {
  // Default the "to" currency with the "from" currency if not specified.
  toCurrency = toCurrency || fromCurrency;
  // Determine the decimal precision, default to use the toCurrency decimal precision if not specified
  decimal = Number.isFinite(decimal) ? decimal : getDecimal(toCurrency);

  let convertedPrice = Number.isFinite(price) ? price : null;
  const convertPrice =
    fromCurrency &&
    toCurrency &&
    fromCurrency.conversionFactor &&
    toCurrency.conversionFactor &&
    fromCurrency.currencyCode !== toCurrency.currencyCode;
  // Convert price, and round to the decimal precision factor
  if (!convertPrice) {
    toCurrency = fromCurrency;
  } else if (
    convertedPrice &&
    toCurrency?.conversionFactor &&
    fromCurrency?.conversionFactor &&
    price
  ) {
    convertedPrice = (toCurrency?.conversionFactor / fromCurrency?.conversionFactor) * price;
  }

  // Truncate price if the decimal strategy = 'trunc'
  if (strategy === 'trunc' && convertedPrice) {
    convertedPrice = Math.trunc(convertedPrice);
  }

  // Round to the decimal precision factor
  if (convertedPrice) {
    const precisionFactor = 10 ** decimal;
    convertedPrice = Math.round(convertedPrice * precisionFactor) / precisionFactor;
  }

  return { currency: toCurrency, price: convertedPrice, decimal };
};

/**
 * Formats the price based on the currency, language, and currency format provided.
 * The currency format supported are: "none", "symbol", "symbol-code"
 *
 * @param {*} price
 * @param {*} language
 * @param {*} currency
 * @param {*} currencyFormat
 * @param {*} decimal
 * @param {*} strategy
 */
export const format = (
  price: number | null | undefined,
  language: string,
  currency: ConversionCurrencyOptions,
  currencyFormat: string,
  decimal: number,
  strategy?: string
) => {
  language = language || 'en';
  const currencyCode =
    currency && currency.currencyCode && currency.currencyCode.trim().toUpperCase();
  currencyFormat = currencyFormat || 'symbol';
  decimal = Number.isFinite(decimal) ? decimal : getDecimal(currency);

  const formatOptions = {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: currencyFormat,
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };

  // Truncate price if the decimal strategy = 'trunc'
  if (strategy === 'trunc' && price) {
    price = Math.trunc(price);
  }

  const formattedPrice = getNumberFormat(language, formatOptions).format(price);

  if (currencyCode === 'AED') {
    return `AED ${formattedPrice}`;
  }

  const currencySymbolFormat = currency && currency.currencySymbolFormat;
  if (
    currencyCode &&
    currencySymbolFormat &&
    currencyFormat === 'symbol-code' &&
    !currencySymbolFormat.includes(currencyCode)
  ) {
    return `${currencySymbolFormat.replace(/\{0\}/, formattedPrice)}${
      currencyCode ? ` ${currencyCode}` : ''
    }`;
  }
  if (currencySymbolFormat && currencyFormat === 'symbol') {
    return currencySymbolFormat.replace(/\{0\}/, formattedPrice);
  }
  if (currencyFormat === 'none') {
    return formattedPrice;
  }

  // if we could not format it then add the currency code to the end
  return `${formattedPrice}${currencyCode ? ` ${currencyCode}` : ''}`;
};

/**
 * Converts and formats the price based on the from & to currencies, language, and currency format provided.
 *
 * @param {*} price
 * @param {*} language
 * @param {*} fromCurrency
 * @param {*} toCurrency
 * @param {*} currencyFormat
 * @param {*} decimal
 * @param {*} strategy
 */
export const convertAndFormat = (
  price: number | null | undefined,
  language: string,
  fromCurrency: ConversionCurrencyOptions,
  toCurrency: ConversionCurrencyOptions,
  currencyFormat: string, //"symbol"
  decimal: number,
  strategy: string //"trunc"
) => {
  const conversion = convert(price, fromCurrency, toCurrency, decimal, strategy);
  return format(
    conversion?.price,
    language,
    conversion.currency,
    currencyFormat,
    conversion.decimal
  );
};
