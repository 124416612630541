import { useCallback, useEffect, useMemo } from 'react';
import { RatePlanCodes } from '../constants/rate-plan-codes';
import { useAppDispatch, useAppState } from '../providers/app-provider';
import { useGetCompanyOfferQuery } from './use-get-company-offer';

import { useWrappedRouter } from './use-wrapped-router';

export const useGetUsePoints = (invalidOrUnavailableOffer?: boolean) => {
  const { constraintErrors, safeQueryParams: queryParameters, router } = useWrappedRouter();
  const { shouldUsePoints, pageType, hasSpecialRate } = useAppState();
  const dispatch = useAppDispatch();
  const {
    data: { company, offer },
  } = useGetCompanyOfferQuery();

  const isSpecialRateSelected = useMemo(() => {
    const hasCorpCode =
      !!queryParameters?.corporateCode &&
      queryParameters?.corporateCode !== 'true' &&
      queryParameters?.corporateCode !== 'false';
    const hasPromoCode =
      !!queryParameters?.promoCode &&
      queryParameters?.promoCode !== 'true' &&
      queryParameters?.promoCode !== 'false';
    const hasOfferId =
      !!offer && queryParameters?.offerId === offer.id && queryParameters?.promoCode !== 'false';
    const hasCompanyName =
      !!company &&
      queryParameters?.pnd?.toLowerCase() === company?.pnd?.toLowerCase() &&
      queryParameters?.corporateCode !== 'false';

    const isSpecialRate = !!(
      !!(
        !!queryParameters?.groupCode ||
        hasCorpCode ||
        hasPromoCode ||
        hasOfferId ||
        hasCompanyName
      ) && !(constraintErrors?.offerId || invalidOrUnavailableOffer)
    );
    return isSpecialRate;
  }, [
    company,
    constraintErrors?.offerId,
    invalidOrUnavailableOffer,
    offer,
    queryParameters?.corporateCode,
    queryParameters?.groupCode,
    queryParameters?.offerId,
    queryParameters?.pnd,
    queryParameters?.promoCode,
  ]);

  //handle special rates
  useEffect(() => {
    const isSpecialRatesDeeplinked =
      !!queryParameters?.aaaRate ||
      queryParameters?.token?.includes('HCAAA') ||
      !!queryParameters?.token.includes(RatePlanCodes.AARP) ||
      queryParameters?.token?.includes('HCARP') ||
      !!queryParameters?.employeeRate ||
      !!queryParameters?.friendsAndFamilyRate ||
      !!queryParameters?.gvmtRates ||
      !!queryParameters?.ownerVIPRate ||
      !!queryParameters?.ownerHGVRate ||
      !!queryParameters?.token.includes(RatePlanCodes.SENIOR) ||
      !!queryParameters?.seniorRate ||
      queryParameters?.token?.includes('GDSSNR') ||
      !!queryParameters?.smbRate ||
      !!queryParameters?.travelAgentRate;

    if (isSpecialRateSelected !== hasSpecialRate || isSpecialRatesDeeplinked)
      dispatch({ type: 'SET_HAS_SPECIAL_RATE', payload: isSpecialRateSelected });
  }, [
    isSpecialRateSelected,
    hasSpecialRate,
    queryParameters?.aaaRate,
    queryParameters?.token,
    queryParameters?.employeeRate,
    queryParameters?.friendsAndFamilyRate,
    queryParameters?.gvmtRates,
    queryParameters?.ownerVIPRate,
    queryParameters?.ownerHGVRate,
    queryParameters?.seniorRate,
    queryParameters?.smbRate,
    queryParameters?.travelAgentRate,
    dispatch,
  ]);
  //Handle route change
  useEffect(() => {
    if (router.isReady) {
      dispatch({ type: 'SET_USE_POINTS', payload: !!queryParameters?.redeemPts });
    }
  }, [dispatch, queryParameters?.redeemPts, router.isReady]);
  const arrivalDate = queryParameters?.arrivalDate;
  const isDayUseRate = !!(arrivalDate && arrivalDate === queryParameters?.departureDate);

  const isDisabled = pageType.isPointsExplorer || isDayUseRate;
  const getIsChecked = useCallback(() => {
    switch (true) {
      case pageType.isPointsExplorer:
        return true;
      case isDisabled:
        return false;
      default:
        return shouldUsePoints;
    }
  }, [isDisabled, pageType.isPointsExplorer, shouldUsePoints]);
  const usePointsData = useMemo(() => {
    const isChecked = getIsChecked();

    let infoPopupMessage: 'specialRatesDisclaimer' | 'dayUseRateDisclaimer' | '' = '';
    if (isDisabled) {
      if (isSpecialRateSelected) infoPopupMessage = 'specialRatesDisclaimer';
      else if (isDayUseRate) infoPopupMessage = 'dayUseRateDisclaimer';
    }

    return { isDisabled, isChecked, infoPopupMessage };
  }, [getIsChecked, isDayUseRate, isDisabled, isSpecialRateSelected]);

  //set initial value
  useEffect(() => {
    const isChecked = getIsChecked();
    if (shouldUsePoints !== isChecked) dispatch({ type: 'SET_USE_POINTS', payload: isChecked });
  }, [isDisabled, shouldUsePoints, pageType.isPointsExplorer, getIsChecked, dispatch]);
  return usePointsData;
};
