import { useLocalStorage } from 'usehooks-ts';

export const useSMBProgramIdLocalStorage = () => {
  const [smbProgramId, setSMBProgramId] = useLocalStorage<number | null>('smbProgramId', null);

  return {
    isSMBProgramIdSet: Boolean(smbProgramId),
    smbProgramId,
    setSMBProgramId,
  };
};
