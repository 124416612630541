/* eslint-disable @typescript-eslint/no-var-requires */
const formatters = require('./index');

const countryToFormatter = {
  US: formatters.getUSAddressFormatter,
  CA: formatters.getUSAddressFormatter,
  JP: formatters.getJPAddressFormatter,
  GB: formatters.getGBAddressFormatter,
  DE: formatters.getDEAddressFormatter,
  AT: formatters.getDEAddressFormatter,
  CH: formatters.getDEAddressFormatter,
};

module.exports = (country, options) => {
  const tCountry = country || 'US';
  if (Object.keys(countryToFormatter).includes(tCountry)) {
    return countryToFormatter[tCountry](options);
  }
  // default
  return countryToFormatter.US(options);
};
