import type { SortByDropDownValues } from '../../utils';

export type SearchUrlParams = {
  [key: string]: string | string[];
  query: string;
  brandCode: string;
  brandName: string;
  corporateCode: string;
  datesFlex: string;
  employeeRate: string;
  friendsAndFamilyRate: string;
  groupCode: string;
  numAdults: string;
  numChildren: string;
  numRooms: string;
  ownerVIPRate: string;
  ownerHGVRate: string;
  page: string;
  placeId: string;
  promoCode: string;
  redeemPts: string;
  sessionToken: string;
  token: string[] | string;
  travelAgentRate: string;
  primarySlug: string;
  arrivalDate: string;
  departureDate: string;
  displayCurrency: string;
  adjoiningRoomStay: string;
  sortBy: SortByDropDownValues;
};

export const RatePlanParamTokenKeys = {
  aaaRate: 'HCAAA',
  aarpRate: 'HCARP',
  governmentOrMilitaryRate: 'GDSGOV',
  seniorRate: 'GDSSNR',
} as const;
