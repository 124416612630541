import { useMemo } from 'react';
import { useAllRegionsQuery } from '../gql/queries';
import type { AllRegionsQuery } from '../gql/types';
import { keepPreviousData } from '@tanstack/react-query';

type RegionsParams = {
  isTypeRegion?: boolean;
  regionName?: string;
  isEnabled?: boolean;
  isLocalized?: boolean;
};

type RegionsDetails = {
  isLoading?: boolean;
  regions?: AllRegionsQuery['na'];
  countryCodes?: string[];
};

export const useGetRegions = ({
  isTypeRegion = false,
  regionName = '',
  isEnabled = true,
}: RegionsParams): RegionsDetails => {
  const { data, isLoading } = useAllRegionsQuery(
    { language: 'en' },
    { enabled: isEnabled, placeholderData: keepPreviousData }
  );

  const { countries, regionList } = useMemo(() => {
    const regionList = [data?.na, data?.others]?.flat() as AllRegionsQuery['na'];
    return {
      regionList,
      countries:
        isTypeRegion && regionName
          ? regionList
              ?.find((region) => region?.name?.toLowerCase() === regionName?.toLowerCase())
              ?.countries?.map((country) => country.code)
          : [],
    };
  }, [data?.na, data?.others, isTypeRegion, regionName]);
  return {
    countryCodes: countries,
    isLoading,
    regions: regionList,
  };
};
