import type { GeocodeCoordinate } from '../gql/types';

const toRadians = (degrees: number): number => degrees * (Math.PI / 180);
const toDegrees = (radians: number) => (radians * 180) / Math.PI;

/**
 * Calculates new coordinates based on initial coordinates, a distance, and a bearing.
 * @param coords - The original coordinate.
 * @param distance - The angular distance.
 * @param bearing - The bearing angle in degrees.
 * @returns The new coordinate.
 */
const calculateCoordinate = (
  coords: GeocodeCoordinate,
  distance: number,
  bearing: number
): GeocodeCoordinate => {
  const latitudeInRadians = toRadians(coords.latitude);
  const latitudeChange = Math.asin(
    Math.sin(latitudeInRadians) * Math.cos(distance) +
      Math.cos(latitudeInRadians) * Math.sin(distance) * Math.cos(toRadians(bearing))
  );
  // Convert back to degrees
  const latitude = toDegrees(latitudeChange);

  // Calculate the change in longitude
  const longitudeChange = Math.atan2(
    Math.sin(toRadians(bearing)) * Math.sin(distance) * Math.cos(latitudeInRadians),

    Math.cos(distance) - Math.sin(latitudeInRadians) * Math.sin(latitudeInRadians)
  );

  // Calculate the new longitude without normalization
  const rawLongitude = coords.longitude + toDegrees(longitudeChange);

  // Add 540 to ensure the value is positive and then take the modulo 360
  const normalizedLongitude = (rawLongitude + 540) % 360;

  // Adjust the value to be in the range of -180 to 180 degrees
  const newLongitude = normalizedLongitude - 180;

  return { latitude, longitude: newLongitude };
};

/**
 * Calculates the bounding box around a central coordinate.
 * @param coords - The central coordinate.
 * @param miles - The radius in miles for the bounding box.
 * @returns An object with southwest and northeast coordinates representing the corners of the bounding box.
 */
export const getBoundsAroundCoords = (coords: GeocodeCoordinate, miles: number) => {
  // Earth's radius in miles
  const earthRadiusMiles = 3959;

  // Angular distance corresponding to the given radius in miles
  const angularDistance = miles / earthRadiusMiles;

  // Calculate the coordinates of the northeast and southwest corners of the bounding box
  const northeast = calculateCoordinate(coords, angularDistance, 45);
  const southwest = calculateCoordinate(coords, angularDistance, 225);

  return { southwest, northeast };
};
