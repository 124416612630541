/* eslint-disable @typescript-eslint/no-var-requires */
const { default: memoizeFormatConstructor } = require('intl-format-cache');
const AddressFormatter = require('./address');

class DEAddressFormatter extends AddressFormatter {
  format(a) {
    const lines = [];

    if (this.pattern === 'short') {
      if (a.city) lines.push(a.city);
      if (a.country) lines.push(a.country);
      return lines.join(', ');
    }

    if (a.addressLine1 || a.addressLine2) {
      const line = [];
      if (a.addressLine1) line.push(a.addressLine1);
      if (a.addressLine2) line.push(a.addressLine2);
      if (line.length && this.pattern === 'stacked') {
        const separator = this.type === 'html' ? '<br/>' : '\n';
        lines.push([line.join(separator)]);
      } else {
        lines.push(line.join(' '));
      }
    }

    if (a.city || a.postalCode) {
      const line = [];
      if (a.postalCode) line.push(a.postalCode);
      if (a.city) line.push(a.city);
      lines.push(line.join(' '));
    }

    if (a.country) lines.push(a.country);

    if (this.pattern === 'long') {
      return lines.join(', ');
    }
    // stacked html
    if (this.type === 'html') {
      return lines.join('<br/>');
    }
    // stacked text
    return lines.join('\n');
  }
}
const getDEAddressFormatter = memoizeFormatConstructor(DEAddressFormatter);

module.exports = getDEAddressFormatter;
