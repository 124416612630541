import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const GetCurrencyConverterCurrenciesDocument = {
  operationName: 'currencies',
  operationString: `query currencies($language: String!) {
  currencies(language: $language, sort: {by: currencyCode}) {
    conversionFactor
    currencyCode
    currencySymbol
    currencySymbolFormat
    description
  }
}`,
  originalOpName: 'getCurrencyConverterCurrencies',
};
export function useGetCurrencyConverterCurrenciesQuery<
  TData = Types.GetCurrencyConverterCurrenciesQuery,
  TError = DXError
>(
  variables: Types.GetCurrencyConverterCurrenciesQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.GetCurrencyConverterCurrenciesQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.GetCurrencyConverterCurrenciesQuery, TError, TData>({
    queryKey: [GetCurrencyConverterCurrenciesDocument, variables],
    ...options,
  });
}
export function serverSideGetCurrencyConverterCurrenciesQuery(
  queryClient: QueryClient,
  variables: Types.GetCurrencyConverterCurrenciesQueryVariables,
  options?: FetchQueryOptions<Types.GetCurrencyConverterCurrenciesQuery>
) {
  return queryClient.fetchQuery<Types.GetCurrencyConverterCurrenciesQuery>({
    queryKey: [GetCurrencyConverterCurrenciesDocument, variables],
    ...options,
  });
}
