/* eslint-disable no-restricted-syntax */
import type {
  HotelAmenityId,
  ShopMultiPropAvailPointsQuery,
  ShopPropStatusType,
} from '../../gql/types';
import type { HotelType, FlatBounds, NestedBounds } from './app-provider.types';
import type { TripAdvisorRatings } from '../../components/filters/filter.constants';
import { HotelAttributeIds } from '../../components/filters/filter.constants';

export type ApplyFiltersToHotelParams = {
  amenities: string[];
  attributes: string[];
  brandCodes: string[];
  rating: TripAdvisorRatings;
  hotel: HotelType;
  pricing?: Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]> | undefined;
  showAvailableHotelsOnly: boolean;
  hasMPACallResolved?: boolean;
  useLeadPrice?: boolean;
  priceRange?: Tuple<2, number>;
  maxPoints?: number;
  shouldUsePoints?: boolean;
  saleFilterEnabled?: boolean;
};
export const applyFiltersToHotel = ({
  amenities,
  attributes,
  brandCodes,
  rating,
  hotel,
  pricing,
  showAvailableHotelsOnly,
  hasMPACallResolved,
  useLeadPrice,
  priceRange,
  maxPoints,
  shouldUsePoints,
  saleFilterEnabled,
}: ApplyFiltersToHotelParams) => {
  let hasAllFilteredAmenities = true;
  let hasAllFilteredAttributes = true;
  let hasAllFilteredBrands = true;
  let isHotelAvailable = true;
  let hasSelectedPriceRange = true;
  let hasSelectedPointsRange = false;
  let hasAllFiltersMatched = true;
  let hasSelectedRating = true;

  let isSoldOutOrComingSoon = false;

  if (!useLeadPrice)
    isSoldOutOrComingSoon = hasMPACallResolved
      ? (['NOT_AVAILABLE', 'NOT_OPEN', 'NOT_BOOKABLE_ONLINE'] as ShopPropStatusType[]).some(
          (status) => pricing?.[hotel?.ctyhocn]?.summary?.status?.type === status
        )
      : true;
  if (useLeadPrice) isSoldOutOrComingSoon = !hotel?.leadRate?.lowest?.rateAmount;

  let hotelPrice;
  if (!useLeadPrice && !!hasMPACallResolved) {
    hotelPrice = shouldUsePoints
      ? pricing?.[hotel?.ctyhocn]?.summary?.hhonors?.dailyRmPointsRate
      : pricing?.[hotel?.ctyhocn]?.summary?.lowest?.rateAmount;
  } else if (useLeadPrice) {
    hotelPrice = shouldUsePoints
      ? hotel?.leadRate?.hhonors?.lead?.dailyRmPointsRate
      : hotel?.leadRate?.lowest?.rateAmount;
  }
  const hasValidMinMaxPts = shouldUsePoints && typeof maxPoints === 'number';

  // run through all selected amenities for each hotel. As soon as one is missing break and dont include this hotel
  if (amenities?.length) {
    for (const amenity of amenities) {
      if (!hotel?.amenityIds?.includes(amenity as HotelAmenityId)) {
        hasAllFilteredAmenities = false;
        break;
      }
    }
  }
  if (attributes?.length) {
    for (const attribute of attributes) {
      if (
        !pricing?.[hotel?.ctyhocn]?.summary?.lowest?.ratePlan?.attributes?.includes(attribute) ||
        (attribute === HotelAttributeIds.sale && !saleFilterEnabled)
      ) {
        hasAllFilteredAttributes = false;
      }
      break;
    }
  }
  if (brandCodes?.length && !brandCodes?.includes(hotel?.brandCode || '')) {
    hasAllFilteredBrands = false;
  }

  // price filter
  if (
    (priceRange && isSoldOutOrComingSoon) ||
    (priceRange && hotelPrice && !(hotelPrice < priceRange[0]))
  ) {
    hasSelectedPriceRange = false;
  }

  // Rating filter
  hasSelectedRating = applyRatingsFilterToHotel(rating, hotel);

  // if available hotel only filter checked then see whether pricing for this property is not one of unavailable types
  if (showAvailableHotelsOnly) {
    isHotelAvailable = (['NOT_AVAILABLE', 'NOT_OPEN'] as ShopPropStatusType[]).every(
      (status) => pricing?.[hotel?.ctyhocn]?.summary?.status?.type !== status
    );
  }

  // filter hotels by points explorer range
  if (
    hasValidMinMaxPts &&
    typeof hotel?.leadRate?.hhonors?.max?.dailyRmPointsRate === 'number' &&
    typeof hotel?.leadRate?.hhonors?.min?.dailyRmPointsRate === 'number' &&
    hotel?.leadRate?.hhonors?.min?.dailyRmPointsRate <= maxPoints
  ) {
    hasSelectedPointsRange = true;
  }

  hasAllFiltersMatched = hasValidMinMaxPts
    ? isHotelAvailable &&
      hasAllFilteredAmenities &&
      hasAllFilteredBrands &&
      hasSelectedPointsRange &&
      hasSelectedRating
    : isHotelAvailable &&
      hasAllFilteredAmenities &&
      hasAllFilteredAttributes &&
      hasAllFilteredBrands &&
      hasSelectedPriceRange &&
      hasSelectedRating;

  return hasAllFiltersMatched;
};

const applyRatingsFilterToHotel = (rating: TripAdvisorRatings, hotel: HotelType) => {
  switch (rating) {
    case 'fiveAndUp':
      return Boolean(
        hotel?.tripAdvisorLocationSummary?.rating && hotel.tripAdvisorLocationSummary.rating >= 5
      );
    case 'fourAndUp':
      return Boolean(
        hotel?.tripAdvisorLocationSummary?.rating && hotel.tripAdvisorLocationSummary.rating >= 4
      );
    case 'threeAndUp':
      return Boolean(
        hotel?.tripAdvisorLocationSummary?.rating && hotel.tripAdvisorLocationSummary.rating >= 3
      );
    case 'twoAndUp':
      return Boolean(
        hotel?.tripAdvisorLocationSummary?.rating && hotel.tripAdvisorLocationSummary.rating >= 2
      );
    default:
      return true;
  }
};

const checkIfHotelCoordinatesAreWithinGivenBounds = (
  north: number,
  east: number,
  south: number,
  west: number,
  lat: number,
  lng: number
) => {
  if (west > east) {
    if (lng > 0) {
      return south < lat && lat < north && west < lng && lng > east;
    }
    return south < lat && lat < north && west > lng && lng < east;
  }
  return south < lat && lat < north && west < lng && lng < east;
};

export const checkIfHotelIsWithinBounds = (
  hotel: HotelType,
  { north, east, south, west }: google.maps.LatLngBoundsLiteral
) => {
  const lat = hotel?.localization?.coordinate?.latitude || 0;
  const lng = hotel?.localization?.coordinate?.longitude || 0;
  if (!lat && !lng) return false;
  return checkIfHotelCoordinatesAreWithinGivenBounds(north, east, south, west, lat, lng);
};

export const toNestedBounds = (flat: FlatBounds): NestedBounds => ({
  northeast: {
    latitude: flat.north,
    longitude: flat.east,
  },
  southwest: {
    latitude: flat.south,
    longitude: flat.west,
  },
});

export const getIsPageBrandFilterEnabled = (brandFilters: string[], brandCode?: string) =>
  brandFilters?.length === 1 && brandFilters[0] === brandCode;
